import { Link } from "react-router-dom";
import { Accprofile } from "./S_AccountProfile";
import { useAuth } from "../utils/AppwriteConfig/AuthContext";
import { Spinner } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SidebarNotification from "./SidebarNotification";
export default function Sidebar({ Role }) {
  const { user, UmLoad, currentPage, setCurrentPage } = useAuth();
  const location = useLocation();

  const [isAccountsEnabled, setisAccountEnabled] = useState(true);
  const [isUserOpen, setisUserOpen] = useState(false);
  useEffect(() => {
    require("preline/preline");
  }, [location.pathname, user]);

  useEffect(() => {
    if (window.HSStaticMethods) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, user]);

  let currentrole;
  if (
    Role.includes("Adminhead") ||
    Role.includes("Registrar") ||
    Role.includes("Scheduler")
  ) {
    currentrole = "employee";
  } else if (Role.includes("teacher")) {
    currentrole = "teacher";
  } else if (Role.includes("student")) {
    currentrole = "student";
  } else {
    currentrole = null;
  }

  return (
    <div
      id="application-sidebar"
      className="hs-overlay bg-gradient-to-r from-[#bf99f0] via-[#bf99f0] to-[#7d458b] [--auto-close:xl] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 start-0 bottom-0 z-40 w-64 bg-white border-e border-gray-200 pt-7  overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 dark:bg-gray-800 dark:border-gray-700"
    >
      {/* <SidebarNotification/> */}
      <div className="absolute inset-0 bg-[#ededed] opacity-40 "></div>
      <div className="flex flex-col h-full relative z-30">
        <div className="flex-initial">
          <div className="px-6 flex-col mt-5 justify-center items-center break-words">
            <div className="w-full flex justify-center item-center">
              <img
                alt="BSAPIC"
                className=" w-[150px] h-[150px] rounded-full"
                src={require("../Img/BSA.jpg")}
                style={{
                  userSelect: "none", // Prevents text/image selection
                  pointerEvents: "none" // Prevents clicking/hovering
                }}
              />
            </div>
            <span
              className="flex justify-center items-center text-center text-xl font-semibold bre text-black "
              href="#"
              aria-label="Brand"
              style={{
                userSelect: "none", // Prevents text/image selection
                pointerEvents: "none" // Prevents clicking/hovering
              }}
            >
              Bulacan Standard Academy
            </span>
          </div>
        </div>

        <div className="flex-1 break-words overflow-auto">
          <nav
            className="hs-accordion-group p-6 w-full h-full flex flex-col overflow-auto [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-transparent [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500"
            data-hs-accordion-always-open
          >
            <ul className="space-y-1.5">
              <li>
                <Link
                  className="flex items-center hover:bg-[#a876e9] gap-x-3 py-2 px-2.5 text-sm text-black rounded-lg "
                  to={`/user/${currentrole}/dashboard`}
                  onClick={() => {
                    setCurrentPage("Dashboard");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-house"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
                  </svg>

                  {currentrole === "teacher" || currentrole === "student" ? (
                    <p>Home</p>
                  ) : (
                    <p>Dashboard</p>
                  )}
                </Link>
              </li>
              {(Role.includes("Adminhead") || Role.includes("Registrar")) && (
                <>
                  <li className="hs-accordion" id="projects-accordion">
                    <button
                      type="button"
                      onClick={() => {
                        setisUserOpen(!isUserOpen);
                      }}
                      className="hs-accordion-toggle w-full text-start rounded-lg hover:bg-[#a876e9] flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-black hs-accordion-active:hover:bg-transparent text-sm text-black hover:text-black "
                    >
                      {isUserOpen ? (
                        <img
                          src={require("../Img/Dashboard/user.gif")}
                          className="size-6"
                        />
                      ) : (
                        <img
                          src={require("../Img/Dashboard/user.png")}
                          className="size-[21.5px]"
                        />
                      )}
                      Users
                      <svg
                        className="hs-accordion-active:block ms-auto hidden w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="m18 15-6-6-6 6" />
                      </svg>
                      <svg
                        className="hs-accordion-active:hidden ms-auto block w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="m6 9 6 6 6-6" />
                      </svg>
                    </button>

                    <div
                      id="projects-accordion-child"
                      className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden"
                    >
                      <ul className="pt-2 ps-2">
                        <Link
                          onClick={(e) => {
                            setCurrentPage("Manage Students");
                            UmLoad && e.preventDefault();
                          }}
                          to="/user/employee/manage/students"
                        >
                          <li className="flex justify-between hover:bg-[#a876e9] rounded-lg">
                            <span className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:bg-gray-800 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-person"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                              </svg>
                              Student
                            </span>
                            {UmLoad && (
                              <Spinner
                                size="sm"
                                color="secondary"
                                className=" mx-2"
                              />
                            )}
                          </li>
                        </Link>

                        <Link
                          onClick={(e) => {
                            setCurrentPage("Manage Teachers");
                            UmLoad && e.preventDefault();
                          }}
                          to="/user/employee/manage/teachers"
                        >
                          <li className="flex justify-between hover:bg-[#a876e9] rounded-lg">
                            <span className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:bg-gray-800 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-people"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                              </svg>
                              Teacher
                            </span>
                            {UmLoad && (
                              <Spinner
                                size="sm"
                                color="secondary"
                                className=" mx-2"
                              />
                            )}
                          </li>
                        </Link>

                        <Link
                          onClick={(e) => {
                            setCurrentPage("Manage Emloyees");
                            UmLoad && e.preventDefault();
                          }}
                          to="/user/employee/manage/employees"
                        >
                          <li className="flex justify-between hover:bg-[#a876e9] rounded-lg">
                            <span className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:bg-gray-800 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-person-plus"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                <path
                                  fillRule="evenodd"
                                  d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
                                />
                              </svg>
                              Employee
                            </span>
                            {UmLoad && (
                              <Spinner
                                size="sm"
                                color="secondary"
                                className=" mx-2"
                              />
                            )}
                          </li>
                        </Link>
                        <Link
                          onClick={(e) => {
                            setCurrentPage("Manage Archives");
                            UmLoad && e.preventDefault();
                          }}
                          to="/user/employee/manage/archived"
                        >
                          <li className="flex justify-between hover:bg-[#a876e9] rounded-lg">
                            <span className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:bg-gray-800 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-person-gear"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                              </svg>
                              Archives
                            </span>
                            {UmLoad && (
                              <Spinner
                                size="sm"
                                color="secondary"
                                className=" mx-2"
                              />
                            )}
                          </li>
                        </Link>
                        {isAccountsEnabled && (
                          <Link
                            onClick={(e) => {
                              setCurrentPage("Manage Disabled Accounts");
                              UmLoad && e.preventDefault();
                            }}
                            to="/user/employee/manage/accounts"
                          >
                            <li className="flex justify-between hover:bg-[#a876e9] rounded-lg">
                              <span className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:bg-gray-800 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-person-exclamation"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                </svg>
                                Accounts
                              </span>
                              {UmLoad && (
                                <Spinner
                                  size="sm"
                                  color="secondary"
                                  className=" mx-2"
                                />
                              )}
                            </li>
                          </Link>
                        )}
                      </ul>
                    </div>
                  </li>
                  <li>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      onClick={() => {
                        setCurrentPage("Courses");
                      }}
                      to="/user/employee/manage/courses"
                    >
                      <div className="flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-bookmark-star-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5M8.16 4.1a.178.178 0 0 0-.32 0l-.634 1.285a.18.18 0 0 1-.134.098l-1.42.206a.178.178 0 0 0-.098.303L6.58 6.993c.042.041.061.1.051.158L6.39 8.565a.178.178 0 0 0 .258.187l1.27-.668a.18.18 0 0 1 .165 0l1.27.668a.178.178 0 0 0 .257-.187L9.368 7.15a.18.18 0 0 1 .05-.158l1.028-1.001a.178.178 0 0 0-.098-.303l-1.42-.206a.18.18 0 0 1-.134-.098z"
                          />
                        </svg>
                      </div>
                      Courses/Grade lvl
                    </Link>
                  </li>
                </>
              )}
              {(Role.includes("student") || Role.includes("teacher")) && (
                <>
                  <li>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      to={`/user/${currentrole}/view/schedule`}
                      onClick={() => {
                        setCurrentPage("Schedule");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-calendar3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                      </svg>
                      Schedule
                    </Link>
                  </li>
                  {Role.includes("teacher") && (
                    <li>
                      <Link
                        className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        to={`/user/${currentrole}/view/subjects/assigned`}
                        onClick={() => {
                          setCurrentPage("Assigned Subjects");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-journal-bookmark"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 8V1h1v6.117L8.743 6.07a.5.5 0 0 1 .514 0L11 7.117V1h1v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8"
                          />
                          <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                          <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                        </svg>
                        Subjects
                      </Link>
                    </li>
                  )}
                  {Role.includes("student") && (
                    <li>
                      <Link
                        className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        to={`/user/${currentrole}/view/subjects/enrolled`}
                        onClick={() => {
                          setCurrentPage("Enrolled Subjects");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-journal-bookmark"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 8V1h1v6.117L8.743 6.07a.5.5 0 0 1 .514 0L11 7.117V1h1v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8"
                          />
                          <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                          <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                        </svg>
                        Subjects
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      to={`/user/${currentrole}/view/bsapurpose`}
                      onClick={() => {
                        setCurrentPage("BSA Purpose");
                      }}
                    >
                      {currentPage === "BSA Purpose" ? (
                        <img
                          src={require("../Img/Dashboard/AboutUs.gif")}
                          className="size-6"
                        />
                      ) : (
                        <img
                          src={require("../Img/Dashboard/AboutUs.png")}
                          className="size-[21.5px]"
                        />
                      )}
                      About us
                    </Link>
                  </li>
                </>
              )}

              {Role.includes("Adminhead") && (
                <>
                  <li>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      onClick={() => {
                        setCurrentPage("Manage Subjects");
                      }}
                      to="/user/employee/manage/subjects"
                    >
                      {currentPage === "Manage Subjects" ? (
                        <img
                          src={require("../Img/Dashboard/subject.gif")}
                          className="size-6"
                        />
                      ) : (
                        <img
                          src={require("../Img/Dashboard/subject.png")}
                          className="size-[21.5px]"
                        />
                      )}
                      Subjects
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      to={`/user/${currentrole}/manage/schedules`}
                      onClick={() => {
                        setCurrentPage("Schedules");
                      }}
                    >
                      {currentPage === "Schedules" ? (
                        <img
                          src={require("../Img/Dashboard/course.gif")}
                          className="size-6"
                        />
                      ) : (
                        <img
                          src={require("../Img/Dashboard/course.png")}
                          className="size-[21.5px]"
                        />
                      )}
                      Schedule
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      onClick={() => {
                        setCurrentPage("Announcements");
                      }}
                      to="/user/employee/manage/announcements"
                    >
                      {currentPage === "Announcements" ? (
                        <img
                          src={require("../Img/Dashboard/announcement.gif")}
                          className="size-6"
                        />
                      ) : (
                        <img
                          src={require("../Img/Dashboard/announcement.png")}
                          className="size-[21.5px]"
                        />
                      )}
                      Announcements
                    </Link>
                  </li>
                </>
              )}
              {Role.includes("Scheduler") && (
                <>
                  <li>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      to={`/user/${currentrole}/manage/schedules`}
                      onClick={() => {
                        setCurrentPage("Schedules");
                      }}
                    >
                      {currentPage === "Schedules" ? (
                        <img
                          src={require("../Img/Dashboard/course.gif")}
                          className="size-6"
                        />
                      ) : (
                        <img
                          src={require("../Img/Dashboard/course.png")}
                          className="size-[21.5px]"
                        />
                      )}
                      Schedule
                    </Link>
                  </li>{" "}
                  <li>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      onClick={() => {
                        setCurrentPage("Announcements");
                      }}
                      to="/user/employee/manage/announcements"
                    >
                      {currentPage === "Announcements" ? (
                        <img
                          src={require("../Img/Dashboard/announcement.gif")}
                          className="size-6"
                        />
                      ) : (
                        <img
                          src={require("../Img/Dashboard/announcement.png")}
                          className="size-[21.5px]"
                        />
                      )}
                      Announcements
                    </Link>
                  </li>
                </>
              )}
              {(Role.includes("Adminhead") || Role.includes("Registrar")) && (
                <>
                  <li>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      to={`/user/${currentrole}/manage/documents`}
                      onClick={() => {
                        setCurrentPage("Documents");
                      }}
                    >
                      {currentPage === "Documents" ? (
                        <img
                          src={require("../Img/Dashboard/document.gif")}
                          className="size-6"
                        />
                      ) : (
                        <img
                          src={require("../Img/Dashboard/document.png")}
                          className="size-[21.5px]"
                        />
                      )}
                      Documents
                    </Link>
                  </li>
                </>
              )}
              {Role.includes("Adminhead") && (
                <li className="hs-accordion" id="projects-accordion">
                  <Link
                    onClick={() => {
                      setCurrentPage("Manage Pages");
                    }}
                    to="/user/employee/manage/pages"
                  >
                    <li>
                      <span className="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] dark:bg-gray-800 dark:text-slate-400 dark:hover:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                        {currentPage === "Manage Pages" ? (
                          <img
                            src={require("../Img/Dashboard/homepage.gif")}
                            className="size-6"
                          />
                        ) : (
                          <img
                            src={require("../Img/Dashboard/homepage.png")}
                            className="size-[21.5px]"
                          />
                        )}
                        Edit Home Page
                      </span>
                    </li>
                  </Link>
                </li>
              )}
              <li>
                <Link
                  className="w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-black rounded-lg hover:bg-[#a876e9] hover:text-black-300 "
                  to={`/user/${currentrole}/manage/calendar`}
                  onClick={() => {
                    setCurrentPage("Calendar");
                  }}
                >
                  {currentPage === "Calendar" ? (
                    <img
                      src={require("../Img/Dashboard/calendar.gif")}
                      className="size-6"
                    />
                  ) : (
                    <img
                      src={require("../Img/Dashboard/calendar.png")}
                      className="size-[21.5px]"
                    />
                  )}
                  Calendar
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="flex-initial">
          <Accprofile />
        </div>
      </div>
    </div>
  );
}
