import {
  Button,
  Card,
  CardFooter,
  Image,
  Pagination,
  Skeleton
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import CustomDropdown from "./components/Dropdowncategory";
import Cardsample from "../../../Components/skeleton/Cards";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Error from "../../../Components/ErrorIndicator";

import { useNavigate } from "react-router-dom";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";
import SubjectModal from "./action/SubjectModal";

export default function Subjects() {
  const { DataServer } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState();
  const [selectedCategory, setSelectedCategory] = useState("All Subjects");
  const [isrefresh, setisrefresh] = useState(true);
  const [Subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [IsPageRefresh, setIsPageRefresh] = useState(true);
  const [Pages, setPages] = useState({
    total: 0,
    current: 1
  });

  const [searchQuery, setsearchQuery] = useState("");
  const [search, setSearch] = useState("");
  // used to prevent multiple request, Itll only request after the user is done typing
  useEffect(() => {
    setIsLoading(true);
    const timerId = setTimeout(() => {
      setsearchQuery(search);
    }, 500);

    // Clean up the timer
    return () => clearTimeout(timerId);
  }, [search]);

  async function fetchsubj(gg) {
    try {
      setIsLoading(true);
    
      const response = await axios.get(`${DataServer}/course/subject/all`, {
        params: { page: Pages.current, search: searchQuery, grade: gg }
      });

      const totalPages = Math.ceil(response.data.total / 8);
      setPages({ current: search ? 1 : Pages.current, total: totalPages });
      setSubjects(response.data.documents);
    } catch (error) {
      setMainError(error.message);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    
  }, [Pages]);
  //Used For FirstRender
  useEffect(() => {
    fetchsubj();
    return () => {};
  }, [isrefresh, Pages.current, searchQuery]);

  //USed For every changes in selcetd subejct
  useEffect(() => {
    if (selectedCategory && selectedCategory !== "All Subjects") {
      fetchsubj(selectedCategory);
    }
  }, [selectedCategory]);

  const handleRealtimeMessage = (message) => {
    const subjectIndex = Subjects.findIndex(
      (subject) => subject.$id === message.payload.$id
    );

    if (message.changetype === "create") {
      setSubjects((prevSubjects) => [...prevSubjects, message.payload]);
    } else if (message.changetype === "update") {
      if (subjectIndex !== -1) {
        setSubjects((prevSubjects) => {
          const updatedSubjects = [...prevSubjects];
          updatedSubjects[subjectIndex] = message.payload;
          return updatedSubjects;
        });
      } else {
        
      }
    } else if (message.changetype === "delete") {
      setSubjects((prevSubjects) =>
        prevSubjects.filter((subject) => subject.$id !== message.payload.$id)
      );
    } else {
      setMainError("Something went wrong in fetching updates");
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_SUBJECT,
    handleRealtimeMessage
  );

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const refresh = () => {
    setisrefresh(!isrefresh);
  };

  const handleEditClick = (subj) => {
    setSelectedSubject(subj);

    document.querySelector("#SubjectModalbtn-edit").click();
  };

  return (
    <div className="w-full h-full sm:pt-4">
      {MainError && <Error errorMessage={MainError} />}
      <div className="flex flex-col overflow-hidden h-full justify-center">
        <div className="flex-initial flex-col sm:flex-row flex justify-between items-center p-2 mb-4">
          <div className=" flex items-center gap-2">
            <span className="font-bold text-3xl text-[#72539b] mb-2">
              Subjects
            </span>
            <div className="flex-1 flex w-full pr-0 mb-2 border-0 ring-0 sm:pr-6 sm:mb-0 relative">
              {/* Search icon */}
              <div className="absolute inset-y-0 left-3 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
              {/* Search user input */}
              <input
                type="text"
                placeholder="Search"
                className="py-2 pl-10 pr-2 border-0 shadow-md block w-full rounded-lg bg-default text-black/90 dark:text-white/90 text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {/* Clear button */}
              {searchQuery && (
                <div
                  className="absolute inset-y-0 right-9 flex cursor-pointer items-center "
                  onClick={() => {
                    setsearchQuery("");
                    setSearch("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg>
                </div>
              )}
            </div>
          </div>
          <div className="flex">
            <CustomDropdown onSelect={handleCategoryChange} />
            <SubjectModal reload={refresh} modalType={"new"} />
          </div>
        </div>

        <div className="flex-initial mb-2 w-full overflow-auto shadow-sm border-1 flex py-4 p-1 items-center  bg-white rounded-lg ">
          <div className="grid p-3 gap-2 sm:grid-cols-3 md:grid-cols-4   flex-1">
            {!isLoading && Subjects && Subjects.length > 0 ? (
              <>
                {selectedCategory === "All Subjects"
                  ? Subjects.map((subj) => <SubjectCard subject={subj} />)
                  : Subjects.map((subj) => <SubjectCard subject={subj} />)}
              </>
            ) : isLoading ? (
              // Loading
              <>
                <Card
                  shadow="sm"
                  className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                >
                  <Skeleton isLoaded={false} className=" w-full ">
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Skeleton>
                </Card>
                <Card
                  shadow="sm"
                  className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                >
                  <Skeleton isLoaded={false} className=" w-full ">
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Skeleton>
                </Card>
                <Card
                  shadow="sm"
                  className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                >
                  <Skeleton isLoaded={false} className=" w-full ">
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Skeleton>
                </Card>
                <Card
                  shadow="sm"
                  className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                >
                  <Skeleton isLoaded={false} className=" w-full ">
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Skeleton>
                </Card>
                <Card
                  shadow="sm"
                  className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                >
                  <Skeleton isLoaded={false} className=" w-full ">
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Skeleton>
                </Card>
                <Card
                  shadow="sm"
                  className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                >
                  <Skeleton isLoaded={false} className=" w-full ">
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Skeleton>
                </Card>
                <Card
                  shadow="sm"
                  className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                >
                  <Skeleton isLoaded={false} className=" w-full ">
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Skeleton>
                </Card>
                <Card
                  shadow="sm"
                  className=" rounded-md overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
                >
                  <Skeleton isLoaded={false} className=" w-full ">
                    <Image
                      removeWrapper
                      alt="Card background"
                      className="z-0 w-full h-full object-cover"
                      src={require("../../../Img/BSA.jpg")}
                    />
                    <CardFooter className="absolute bg-[#72539b]/80 bottom-0 flex-wrap z-10 rounded-md">
                      <div className="flex flex-grow gap-2 items-center">
                        <div className="flex flex-col">
                          <p className=" text-medium text-white/90">
                            idagfsdkjfnsda
                          </p>
                        </div>
                      </div>
                      <button
                        // onClick={() => handleEditClick(subj)}
                        className="bg-[#FFDE59] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                      >
                        Edit
                      </button>
                    </CardFooter>{" "}
                  </Skeleton>
                </Card>
              </>
            ) : (
              // No Subjects
              <div>No subjects</div>
            )}
          </div>
        </div>
        <div className=" flex-initial flex justify-center">
          <Pagination
            className="flex-initial p-4"
            loop
            isDisabled={isLoading || selectedCategory !== "All Subjects"}
            showControls
            color="secondary"
            total={Pages.total}
            initialPage={1}
            page={Pages.current}
            onChange={(setCurrentPage) =>
              setPages((prevPages) => ({
                ...prevPages,
                current: setCurrentPage
              }))
            }
          />
        </div>
        <SubjectModal
          reload={refresh}
          modalType={"edit"}
          SubjectToEdit={selectedSubject}
        />
      </div>
    </div>
  );

  function SubjectCard({ subject }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleDescription = (event) => {
      event.stopPropagation(); // Prevents click from bubbling to parent div
      setIsExpanded(!isExpanded);
    };

    return (
      <div
        onClick={() => handleEditClick(subject)}
        className="flex flex-col max-w-full p-6 cursor-pointer bg-purple-50 rounded-lg shadow-lg  text-center mx-auto transition-transform duration-300 transform hover:scale-105"
      >
        <h2 className="text-xl font-semibold break-words text-purple-900 mb-2">
          {subject && subject.subjectName && subject.subjectName}
        </h2>
        <p
          className={`text-purple-700 mb-4 transition-all duration-300 overflow-hidden ${
            isExpanded ? "max-h-48" : "max-h-16"
          }`}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: isExpanded ? "none" : "2", // This keeps the ellipsis effect when collapsed
            WebkitBoxOrient: "vertical"
          }}
        >
          {subject && subject.subjectDescription && subject.subjectDescription}
        </p>
        <button
          className="text-purple-900 text-sm font-medium hover:font-bold transition-all mb-4"
          onClick={toggleDescription}
        >
          {isExpanded ? "See less" : "See more"}
        </button>
        <div className="text-purple-900 text-sm mb-2">
          Total Time:{" "}
          {subject &&
            subject.totalSubjectTimePerWeek &&
            subject.totalSubjectTimePerWeek}{" "}
          minutes/week
        </div>
        <div className="text-purple-700 font-semibold">
          Category: {subject && subject.type && subject.type}{" "}
        </div>
      </div>
    );
  }
}

{
  /* <div className="flex flex-1 gap-2 pe-2 items-center w-full bg-red-600 overflow-hidden">
                            <div className="flex flex-col flex-1">
                              <p className="text-medium text-white/90 whitespace-nowrap transition-transform duration-300 hover:animate-marquee">
                                {subj.subjectName}
                              </p>
                              <p className="text-tiny text-white/90">
                                show more
                              </p>
                            </div>
                            <div className="flex-initial bg-orange-300">
                              <button
                                onClick={() => handleEditClick(subj)}
                                className="bg-[#FFDE59] flex-initial w-min-[50px] opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
                              >
                                Edit
                              </button>
                            </div>
                          </div> */
}
