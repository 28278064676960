import React from "react";
import { Card, Skeleton } from "@nextui-org/react";

export default function CardSkeleton() {
  return (
    <Skeleton
      className="w-full m-1 min-h-[220px] rounded-md max-h-[250px] space-y-5 p-4"
      radius="lg"
    >
      {/* <Skeleton className="rounded-lg">
        <div className="h-24 rounded-lg bg-default-300"></div>
      </Skeleton>
      <div className="space-y-3">
        <Skeleton className="w-3/5 rounded-lg">
          <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
        </Skeleton>
        <Skeleton className="w-4/5 rounded-lg">
          <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
        </Skeleton>
        <Skeleton className="w-2/5 rounded-lg">
          <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
        </Skeleton>
      </div> */}
    </Skeleton>
  );
}
{
  /* <Card
  shadow="none"
  className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
>
  <Image
    removeWrapper
    alt="Card background"
    className="z-0 w-full h-full object-cover"
    src={require("../../../../Img/BSA.jpg")}
  />
  <CardFooter className="absolute bg-[#72539b]/80 bottom-0 z-10 rounded-md">
    <div className="flex  w-full gap-2 items-center">
      <div className="flex w-full text-white flex-col">
        <p className=" text-lg text-white/90">{subject.subject.subjectName}</p>
        <div className=" flex  bg-secondary-50 w-full text-sm p-1 px-2 text-secondary-700 rounded-lg">
          {subject.course.courseName} | {subject.section.sectionName}
        </div>
      </div>
    </div>
  </CardFooter>
</Card>; */
}
