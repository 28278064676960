import { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip
} from "@nextui-org/react";

export default function StudentSchedule({ schedule, dayz }) {
  const [selectedKey, setSelectedKey] = useState(null);
  const currentDay = new Date().getDay();
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    const updateSelectedTimeSlot = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();

      // Convert time to a string in "HH:MM AM/PM" format for comparison
      const formattedTime = `${currentHour % 12 || 12}:${
        currentMinute < 10 ? `0${currentMinute}` : currentMinute
      } ${currentHour >= 12 ? "PM" : "AM"}`;

      // Find the matching time slot
      const matchingSlot = Object.keys(schedule).find((timeSlot) => {
        const [start, end] = timeSlot.split(" - ");

        // Create Date objects for comparison
        const formattedTimeDate = new Date(`1970/01/01 ${formattedTime}`);
        const startDate = new Date(`1970/01/01 ${start}`);
        const endDate = new Date(`1970/01/01 ${end}`);
 
        // Check if formattedTime is within the range of start and end times
        return formattedTimeDate >= startDate && formattedTimeDate <= endDate;
      });
    

      // If a matching time slot is found, update the selected key
      if (matchingSlot) {
   
        setSelectedKey(matchingSlot);
      }
    };

    // Check every minute to update the selected time slot
    const interval = setInterval(updateSelectedTimeSlot, 60000);

    // Run immediately when component mounts
    updateSelectedTimeSlot();

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [schedule]);

  return (
    <div className=" w-full h-full max-w-full max-h-full overflow-auto overflow-y-auto">
      <Table
        radius="none"
        shadow="none"
        selectedKeys={
          selectedKey && selectedKey === daysOfWeek[currentDay]
            ? [selectedKey]
            : []
        }
        color="secondary"
        selectionMode="single"
        className="border-0 rounded-none "
        classNames={{ tr: "  pointer-events-none" }}
        aria-label="Example static collection table"
      >
        <TableHeader>
          <TableColumn>Time</TableColumn>
          <TableColumn>Subject</TableColumn>
          <TableColumn>Teacher</TableColumn>
        </TableHeader>
        <TableBody className="gap-2">
          {Object.entries(schedule).map(([timeSlot, subjects], index) => {
            const subjectForDay = subjects.find((subj) => subj.day === dayz);

            if (!subjectForDay) return null;

            return (
              <TableRow key={`${timeSlot}`} className=" mx-2">
                <TableCell
                  className={`${
                    (subjectForDay.type === "break" ||
                      subjectForDay.type === "lunch") &&
                    "bg-secondary-400 rounded-s-md"
                  }  min-w-[150px]`}
                >
                  {subjectForDay.type !== "break" &&
                    subjectForDay.type !== "lunch" &&
                    timeSlot}{" "}
                </TableCell>
                <TableCell
                  className={`${
                    (subjectForDay.type === "break" ||
                      subjectForDay.type === "lunch") &&
                    "bg-secondary-400 text-center text-white"
                  } font-medium  `}
                >
                  {subjectForDay.type === "break" ||
                  subjectForDay.type === "lunch" ? (
                    subjectForDay.type.charAt(0).toUpperCase() +
                    subjectForDay.type.slice(1)
                  ) : (
                    <div className="p-2 py-4">{subjectForDay.subjectName}</div>
                  )}
                </TableCell>
                <TableCell
                  className={`${
                    (subjectForDay.type === "break" ||
                      subjectForDay.type === "lunch") &&
                    "bg-secondary-400 rounded-e-md"
                  }  `}
                >
                  {subjectForDay.teacherName &&
                    subjectForDay.type !== "break" &&
                    subjectForDay.type !== "lunch" && (
                      <div>{subjectForDay.teacherName}</div>
                    )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
