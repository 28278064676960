import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Select,
  SelectItem,
  Tabs,
  Tab,
  Card,
  CardBody,
  CheckboxGroup,
  Checkbox,
  TimeInput,
  Input,
  Slider,
  Switch,
  cn
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import {
  GetCurrentAcademicYear,
  GetPreviousAcademicYears
} from "../../../../utils/helpers/stringformat";
import { parseAbsoluteToLocal, Time } from "@internationalized/date";
import ResponsiveToolTip from "../../../../Components/ResponsiveTooltip";
import AdvisorWarning from "./renders/ActivatedAdvisorFirst";
import Error from "../../../../Components/ErrorIndicator";

export default function GenerateSchedConfigs({ department, academicyear }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();

  const [advisors, setAdvisors] = useState({});
  const [MainError, setMainError] = useState("");

  const [configs, setConfigs] = useState({
    academicYear: GetCurrentAcademicYear(),
    isAdvisorPriority: false,
    advisorySubject: {},
    type: "whole",
    department: "",
    sem: 0
  });

  useEffect(() => {
    console.log("ETO", configs.sem);
  }, [configs.sem]);

  //Schedule COnfigs
  const [BLConfigs, setBLConfigs] = useState({
    BLConfig: "default", // default or alternate
    break: new Time(10), //time,
    totalBreak: 20,
    lunch: new Time(12), //time
    totalLunch: 40
  });

  const [ScheduleConfigs, setScheduleConfigs] = useState({
    scheduleConfig: "normalScheduling",
    academicYear: "",
    start: new Time(7, 30),
    dismissal: new Time(15)
  });
  const [Errors, setErrors] = useState({
    start: "",
    dismissal: "",
    break: "",
    lunch: ""
  }); // Helper function to convert time to minutes since midnight
  useEffect(() => {
    let config;
    let totalbreak = BLConfigs.totalBreak,
      totallunch = BLConfigs.totalLunch,
      cbreak = BLConfigs.break,
      lunch = BLConfigs.lunch;
    if (configs.department === "Elem") {
      config = "normalScheduling";
      totalbreak = 20;
      totallunch = 40;
      cbreak = new Time(10);
      lunch = new Time(12);
    } else if (configs.department === "JHS" || configs.department === "SHS") {
      config = "allSubject1h";
      totalbreak = 30;
      totallunch = 30;
      cbreak = new Time(10);
      lunch = new Time(12, 30);
    } else if (configs.department === "Pre") {
      config = "normalScheduling";
      setIsAdvisorPriority(false);
      totalbreak = 30;
      totallunch = 40;
      cbreak = new Time(9);
      lunch = new Time(12);
    } else {
      config = "normalScheduling";
    }
    setScheduleConfigs({ ...ScheduleConfigs, scheduleConfig: config });
    setBLConfigs({
      ...BLConfigs,
      totalBreak: totalbreak,
      totalLunch: totallunch,
      break: cbreak,
      lunch: lunch
    });
  }, [configs.department]);
  const timeToMinutes = (time) => time.hour * 60 + time.minute;

  const [academicYear, setAcademicYear] = useState("");
  const [error, setError] = useState("");

  console.log(configs);

  // Function to validate the academic year format (YYYY-YYYY)
  const validateAcademicYear = (value) => {
    const regex = /^\d{4}-\d{4}$/;
    const [startYear, endYear] = value.split("-").map(Number);

    // Validate format and logical order of years
    if (!regex.test(value) || endYear - startYear !== 1) {
      setError("Please enter a valid academic year (e.g., 2023-2024).");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    if (error === "" && academicYear !== "") {
      setScheduleConfigs({ ...ScheduleConfigs, academicYear: academicYear });
    }
  }, [academicYear]);

  const handleChange = (e) => {
    const value = e.target.value;
    setAcademicYear(value);
    validateAcademicYear(value);
  };

  useEffect(() => {
    console.log(advisors);
  }, [advisors]);

  useEffect(() => {
    setAcademicYear(academicYear);
    setScheduleConfigs({ ...ScheduleConfigs, academicYear: academicyear });
  }, [academicyear]);

  // VALIDATOR
  useEffect(() => {
    try {
      const startMinutes = timeToMinutes(ScheduleConfigs.start);
      const dismissalMinutes = timeToMinutes(ScheduleConfigs.dismissal);
      const breakMinutes = timeToMinutes(BLConfigs.break);
      const lunchMinutes = timeToMinutes(BLConfigs.lunch);

      // 6:30 AM is 390 minutes from midnight, 8:00 PM is 1200 minutes from midnight
      const MIN_START_TIME = 390;
      const MAX_END_TIME = 1200;
      const MIN_TIME_BEFORE_BREAK = 30;
      const MIN_TIME_BEFORE_LUNCH = 30;

      let errors = {}; // Initialize an empty object for errors

      // Validation for start time
      if (startMinutes < MIN_START_TIME) {
        errors.start = "Start time should be 6:30 AM or later";
      }

      // Validation for dismissal time
      if (dismissalMinutes > MAX_END_TIME) {
        errors.dismissal = "Dismissal time should be no later than 8:00 PM";
      }

      // Validation for break time
      if (breakMinutes - startMinutes < MIN_TIME_BEFORE_BREAK) {
        errors.break = "There must be at least 30 minutes before the break";
      }

      // Validation for lunch time
      if (lunchMinutes - breakMinutes < MIN_TIME_BEFORE_LUNCH) {
        errors.lunch =
          "There must be at least 30 minutes between the break and lunch";
      }

      // If errors object is not empty, set the errors and throw an error
      if (Object.keys(errors).length > 0) {
        setErrors(errors); // Set the errors in the state
        const errorMessages = Object.values(errors).filter(
          (error) => error !== ""
        );

        if (errorMessages.length > 0) {
          // Concatenate the errors with a comma separator
          const concatenatedErrors = errorMessages.join(", ");
          setMainError(concatenatedErrors);
        }
        throw new Error("Validation failed");
      }

      // Clear errors if all validations pass
      setErrors({
        start: "",
        dismissal: "",
        break: "",
        lunch: ""
      });
    } catch (err) {
      console.error("Validation error:", err.message);
    }
  }, [BLConfigs, ScheduleConfigs]);

  function selectASubject(subj) {
    setConfigs({ ...configs, advisorySubject: subj });
  }

  const [AdvisorStatus, setAdvisorStatus] = useState(false);

  const [isAdvisorPriority, setIsAdvisorPriority] = useState(false);

  useEffect(() => {
    setConfigs({ ...configs, isAdvisorPriority: isAdvisorPriority });
  }, [isAdvisorPriority]);

  //AssignmentCOnfigs
  const [AssignmentConfigs, setAssignmentConfigs] =
    useState("DefaultAssignments");

  //Checkbox of Assignment data prioritazion
  const [isPriorityStarSection, setIsPriorityStarSection] = useState(false);
  const [isPriorityMajorSubjects, setIsPriorityMajorSubjects] = useState(false);
  const [isPriorityHigherGrades, setIsPriorityHigherGrades] = useState(false);

  const [sortPriority, setSortPriority] = useState([]);
  const [PrioNumbering, setPrioNumbering] = useState([]);

  useEffect(() => {
    let PrioritizationNumbering = sortPriority;
    console.log(PrioritizationNumbering);
    setPrioNumbering(PrioritizationNumbering);
  }, [sortPriority]);

  // Function to update sortPriority based on checkbox state
  const togglePriority = (priority) => {
    // Check if priority is already in the array
    const currentIndex = sortPriority.indexOf(priority);

    if (currentIndex === -1) {
      // Priority is not in the array, add it to the beginning
      setSortPriority([...sortPriority, priority]);
    } else {
      // Priority is already in the array, remove it
      const newPriorityOrder = [...sortPriority];
      newPriorityOrder.splice(currentIndex, 1);
      setSortPriority(newPriorityOrder);
    }
  };

  //Views
  const [selectedView, setSelectedView] = useState("teachers");

  //Suitability factors
  const [isSubjectTaughtConsidered, setisSubjectTaughtConsidered] =
    useState(true);
  const [isDegreeConsidered, setisDegreeConsidered] = useState(true);
  const [isExperienceConsidered, setisExperienceConsidered] = useState(true);
  const [isPreferencesConsidered, setisPreferencesConsidered] = useState(true);
  const [factors, setFactors] = useState([]);

  const [weights, setWeights] = useState({
    subjectTaught: 80,
    degree: 30,
    experience: 2,
    preference: 100
  });
  const [totalWeights, ssetTotalWeights] = useState({
    subjectTaught: 0,
    degree: 0,
    experience: 0,
    preferences: 0
  });

  //Calculate percentage of TOTAL WEIGHTS
  useEffect(() => {
    const timerId = setTimeout(() => {
      const { subjectTaught, degree, experience, preference } = weights;
      const total = subjectTaught + degree + experience + preference;

      if (total !== 0) {
        const normalizedWeights = {
          subjectTaught: isSubjectTaughtConsidered
            ? (subjectTaught / total) * 100
            : 0,
          degree: isDegreeConsidered ? (degree / total) * 100 : 0,
          experience: isExperienceConsidered ? (experience / total) * 100 : 0,
          preferences: isPreferencesConsidered ? (preference / total) * 100 : 0
        };
        ssetTotalWeights({ ...normalizedWeights });
      }
    }, 500);

    // Clean up the timer
    return () => clearTimeout(timerId);
  }, [weights]);

  useEffect(() => {
    // Use a Set to manage unique factors
    const factorsSet = new Set(factors);

    // Add or remove factors based on state values
    if (isSubjectTaughtConsidered) {
      factorsSet.add("subjectTaught");
    } else {
      factorsSet.delete("subjectTaught");
    }

    if (isDegreeConsidered) {
      factorsSet.add("degree");
    } else {
      factorsSet.delete("degree");
    }

    if (isExperienceConsidered) {
      factorsSet.add("experience");
    } else {
      factorsSet.delete("experience");
    }

    if (isPreferencesConsidered) {
      factorsSet.add("preferences");
    } else {
      factorsSet.delete("preferences");
    }

    // Convert Set to array and update state only if it has changed
    const newFactorsArray = Array.from(factorsSet);
    if (newFactorsArray.join() !== factors.join()) {
      setFactors(newFactorsArray);
    }
  }, [
    isSubjectTaughtConsidered,
    isDegreeConsidered,
    isExperienceConsidered,
    isPreferencesConsidered,
    factors
  ]);

  return (
    <>
      {MainError && (
        <Error
          errorMessage={MainError}
          reset={setMainError}
          title="Input Errors"
        />
      )}
      <Button id="Configs" onPress={onOpen} className="bg-[#ffde59] flex-1">
        Generate Schedule
      </Button>
      <Modal
        isOpen={isOpen}
        size="lg"
        className=" font-poppins transition-all delay-200 ease-in-out"
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-[#72539b] pb-0 text-2xl font-bold">
                Configurations
              </ModalHeader>
              <ModalBody className="">
                <div className="flex w-full flex-col">
                  <Tabs
                    aria-label="Dynamic tabs"
                    color="secondary"
                    variant="underlined"
                    shouldSelectOnPressUp
                  >
                    <Tab title="General" className="p-0">
                      <Card className="shadow-none">
                        <CardBody className=" space-y-2 min-h-[250px]">
                          <div>
                            <Select
                              labelPlacement="outside-left"
                              label="Department"
                              // disabledKeys={["SHS"]}
                              placeholder="Select A Department"
                              selectedKeys={[configs?.department]}
                              className="w-full items-center"
                              onChange={(e) => {
                                setConfigs({
                                  ...configs,
                                  department: e.target.value
                                });
                              }}
                            >
                              <SelectItem key="Pre" value="Pre">
                                Pre-School
                              </SelectItem>
                              <SelectItem key="Elem" value="Elem">
                                Elementary
                              </SelectItem>
                              <SelectItem key="JHS" value="JHS">
                                Junior High-School
                              </SelectItem>
                              <SelectItem key="SHS" value="SHS">
                                Senior High-School
                              </SelectItem>
                            </Select>
                          </div>
                          {/* Academic Year */}
                          <div className="flex w-full items-center space-x-2">
                            {/* <div className="w-full flex gap-2  items-center">
                              <label
                                htmlFor="academicYear"
                                className="block mb-2 text-sm items-center flex-initial"
                              >
                                Academic Year
                              </label>
                              <input
                                type="text"
                                id="academicYear"
                                value={academicYear}
                                placeholder="YYYY-YYYY (e.g., 2023-2024)"
                                onChange={handleChange}
                                className={`w-full p-2  rounded-xl flex-1 bg-[#f4f4f5] ${
                                  error
                                    ? "border-red-500 border"
                                    : "border-none"
                                }`}
                              />
                            </div>
                            {error && (
                              <p className="text-red-500 text-sm mt-1">
                                {error}
                              </p>
                            )} */}
                            <span className=" flex-initial text-sm   ">
                              Academic year
                            </span>
                            <span className="flex-1 bg-[#f4f4f5] h-10 rounded-xl flex justify-center items-center">
                              {academicyear}
                            </span>
                          </div>
                          {configs.department === "SHS" && (
                            <div>
                              <Select
                                labelPlacement="outside-left"
                                label="Semester"
                                // disabledKeys={["SHS"]}
                                placeholder="Select A Department"
                                selectedKeys={[configs?.sem]}
                                className="w-full items-center"
                                onChange={(e) => {
                                  setConfigs({
                                    ...configs,
                                    sem: e.target.value
                                  });
                                }}
                              >
                                <SelectItem key={1} value={1}>
                                  1st Semester
                                </SelectItem>
                                <SelectItem key={2} value={2}>
                                  2nd Semester
                                </SelectItem>
                              </Select>
                            </div>
                          )}
                          <Switch
                            isDisabled={
                              configs.department === "Pre" ||
                              configs.department === "SHS"
                            }
                            color="secondary"
                            isSelected={
                              configs.department === "Pre"
                                ? false
                                : isAdvisorPriority
                            }
                            onValueChange={setIsAdvisorPriority}
                            classNames={{
                              base: cn(
                                "inline-flex flex-row-reverse w-full max-w-md bg-content1 hover:bg-content2 items-center",
                                "justify-between cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                                "data-[selected=true]:border-secondary"
                              ),
                              wrapper: "p-0 h-4 overflow-visible",
                              thumb: cn(
                                "w-6 h-6 border-2 shadow-lg",
                                "group-data-[hover=true]:border-secondary",
                                //selected
                                "group-data-[selected=true]:ml-6",
                                // pressed
                                "group-data-[pressed=true]:w-7",
                                "group-data-[selected]:group-data-[pressed]:ml-4"
                              )
                            }}
                          >
                            <div className="flex flex-col gap-1">
                              <p className="text-medium">
                                Adviser Priority Assignment
                              </p>
                              <p className="text-tiny text-default-400">
                                Prioritize section advisers first for daily
                                subject assignments.
                              </p>
                            </div>
                          </Switch>
                          {/* ADVISOR WARNING */}
                          <AdvisorWarning
                            isPriority={isAdvisorPriority}
                            subjectToTeach={selectASubject}
                            ParentUpdate={setAdvisorStatus}
                            department={configs.department}
                            Advisors={setAdvisors}
                          />
                        </CardBody>
                      </Card>
                    </Tab>
                    <Tab title="Assignment Configs" className="p-0">
                      <Card className=" shadow-none">
                        <CardBody className="  shadow-none">
                          <div className="max-h-[300px] shadow-none overflow-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
                            <div className=" pr-2">
                              {/* Assignment Prioritization */}
                              <div className="border-b-1 p-1">
                                <div className="py-1 flex gap-1 items-center">
                                  <span className="text-xs font-normal text-[#71717a]">
                                    Assignment Data Prioritazion
                                  </span>
                                  <ResponsiveToolTip
                                    tooltipIconColor="#71717a"
                                    tooltipTitle="Customizing Your Priorities"
                                    tooltipMessage="You have the flexibility to change the prioritization order at any time by simply unchecking and rechecking the boxes in the desired sequence."
                                  />
                                </div>
                                <button
                                  onClick={() => {
                                    setIsPriorityStarSection(
                                      !isPriorityStarSection
                                    );
                                    togglePriority("StarSection");
                                    // setIsChanged(true);
                                  }}
                                  className="flex w-full relative items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
                                >
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-stars"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
                                    </svg>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="w-full flex flex-col  items-start justify-center">
                                      <span className="flex-1 text-small font-normal truncate">
                                        Prioritize Star Sections
                                      </span>
                                      <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                                        Reload data to prioritize assignments
                                        for all Section 1 first.
                                      </span>
                                    </div>
                                  </div>
                                  <input
                                    id="PrioritizeStarSection"
                                    name="PrioritizeStarSection"
                                    type="checkbox"
                                    checked={isPriorityStarSection}
                                    className={`${
                                      isPriorityStarSection && "hidden"
                                    } shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                                  ></input>
                                  <div
                                    className={`${
                                      !isPriorityStarSection && "hidden"
                                    } items-center text-white  bg-secondary rounded-full px-[7px] py-[1px]`}
                                  >
                                    {PrioNumbering.indexOf("StarSection") + 1}
                                  </div>
                                </button>
                                <button
                                  onClick={() => {
                                    setIsPriorityMajorSubjects(
                                      !isPriorityMajorSubjects
                                    );
                                    togglePriority("MajorSubjects");
                                    // setIsChanged(true);
                                  }}
                                  className="flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
                                >
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-award-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864z" />
                                      <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z" />
                                    </svg>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="w-full flex flex-col text-secondary items-start justify-center">
                                      <span className="flex-1 text-small font-normal truncate">
                                        Prioritize Majors Subjects
                                      </span>
                                      <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                                        Reload data to prioritize assignments
                                        for subjects with the most hours per
                                        week first.
                                      </span>
                                    </div>
                                  </div>
                                  <input
                                    id="PrioritizeMajorsSubjects"
                                    name="PrioritizeMajorsSubjects"
                                    type="checkbox"
                                    checked={isPriorityMajorSubjects}
                                    className={`${
                                      isPriorityMajorSubjects && "hidden"
                                    } shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                                  ></input>
                                  <div
                                    className={`${
                                      !isPriorityMajorSubjects && "hidden"
                                    } items-center text-white  bg-secondary rounded-full px-[7px] py-[1px]`}
                                  >
                                    {PrioNumbering.indexOf("MajorSubjects") + 1}
                                  </div>
                                </button>{" "}
                                <button
                                  onClick={() => {
                                    setIsPriorityHigherGrades(
                                      !isPriorityHigherGrades
                                    );
                                    togglePriority("HigherGrades");
                                    // setIsChanged(true);
                                  }}
                                  className="flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700"
                                >
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-filter-square-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm.5 5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1 0-1M4 8.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m2 3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5" />
                                    </svg>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="w-full flex flex-col text-secondary items-start justify-center">
                                      <span className="flex-1 text-small font-normal truncate">
                                        Prioritize Higher Grades
                                      </span>
                                      <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                                        Reload data to prioritize assignments
                                        for higher grade levels first.
                                      </span>
                                    </div>
                                  </div>
                                  <input
                                    id="PrioritizeHigherGrades"
                                    name="PrioritizeHigherGrades"
                                    type="checkbox"
                                    checked={isPriorityHigherGrades}
                                    className={`${
                                      isPriorityHigherGrades && "hidden"
                                    } shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                                  ></input>
                                  <div
                                    className={`${
                                      !isPriorityHigherGrades && "hidden"
                                    } items-center text-white  bg-secondary rounded-full px-[7px] py-[1px]`}
                                  >
                                    {PrioNumbering.indexOf("HigherGrades") + 1}
                                  </div>
                                </button>
                              </div>
                              {/* Assignment Configs */}
                              <div className="border-b-1 p-1">
                                <div className="py-1 flex items-center gap-1">
                                  <span className="text-xs font-normal text-[#71717a] my-2">
                                    Teacher Assignment Configuration
                                  </span>
                                  {/* <ResponsiveToolTip
                                    tooltipIconColor="#71717a"
                                    tooltipTitle="What is Teacher Assignment Configurations ?"
                                    tooltipMessage="This allows you to choose how teachers are assigned to subjects."
                                  /> */}
                                </div>
                                <button
                                  onClick={() => {
                                    setAssignmentConfigs("DefaultAssignments");
                                  }}
                                  className={`${
                                    AssignmentConfigs ===
                                      "DefaultAssignments" && "bg-warning-50"
                                  } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-warning-600 hover:bg-gray-100 focus:outline-none focus:bg-warning-50 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                                >
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-list-columns-reverse"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M0 .5A.5.5 0 0 1 .5 0h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 .5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10A.5.5 0 0 1 4 .5m-4 2A.5.5 0 0 1 .5 2h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 4h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 6h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 8h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
                                      />
                                    </svg>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="w-full flex flex-col items-start justify-center">
                                      <span
                                        id="react-aria1555630211-:r69:"
                                        className="flex-1 text-small font-normal truncate"
                                      >
                                        Most Suitable Assignments
                                      </span>
                                      <span
                                        id="react-aria1555630211-:r6a:"
                                        className="w-full  text-tiny text-left text-foreground-500 group-hover:text-current"
                                      >
                                        This will assign the most suitable
                                        teacher first
                                      </span>
                                    </div>
                                  </div>
                                </button>
                                {/* <button
                                  onClick={() => {
                                    setAssignmentConfigs(
                                      "BalanceScheduleperTeacher"
                                    );
                                  }}
                                  className={`${
                                    AssignmentConfigs ===
                                      "BalanceScheduleperTeacher" &&
                                    "bg-warning-50"
                                  } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-warning-600 hover:bg-gray-100 focus:outline-none focus:bg-warning-50 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                                >
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-diagram-3-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5z"
                                      />
                                    </svg>
                                  </div>
                                  <div className="flex flex-col">
                                    <div className="w-full flex flex-col items-start justify-center">
                                      <span
                                        id="react-aria1555630211-:r69:"
                                        className="flex-1 text-small font-normal truncate"
                                      >
                                        Balance Schedule per Teacher
                                      </span>
                                      <span
                                        id="react-aria1555630211-:r6a:"
                                        className="w-full text-tiny text-left text-foreground-500 group-hover:text-current"
                                      >
                                        This ensure a balanced schedule for each
                                        teacher.
                                      </span>
                                    </div>
                                  </div>
                                </button> */}
                              </div>
                              {/* Suitability Factors */}
                              <div
                                className={`${
                                  factors.length <= 0 &&
                                  "border border-red-400 p-2 pt-0 rounded-lg"
                                }`}
                              >
                                <div className="py-1 flex items-center gap-1">
                                  <span className="text-xs font-normal text-[#71717a]">
                                    Suitability Evaluation Factors
                                  </span>
                                  <ResponsiveToolTip
                                    tooltipIconColor="#71717a"
                                    tooltipTitle="What is Suitability Evaluation Factors ?"
                                    tooltipMessage="
Suitability Evaluation Factors allow you to define and adjust the criteria for assessing suitability, customizing the evaluation to emphasize the most relevant aspects for your specific needs."
                                  />
                                </div>
                                {/* Teacher's Taught Subject relevance */}
                                <div></div>
                                <div className="flex flex-col w-full  items-center  rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700">
                                  <button
                                    onClick={() => {
                                      setisSubjectTaughtConsidered(
                                        !isSubjectTaughtConsidered
                                      );
                                      if (!isSubjectTaughtConsidered) {
                                        setWeights({ ...weights });
                                      } else {
                                        ssetTotalWeights({
                                          ...totalWeights,
                                          subjectTaught: 0
                                        });
                                      }
                                    }}
                                    className="flex w-full relative items-center gap-x-3.5 py-2 px-3"
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-journal-bookmark-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8z"
                                        />
                                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
                                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full flex flex-col  items-start justify-center">
                                        <span className="flex-1 text-small font-normal truncate">
                                          Subject Taught{" "}
                                          {totalWeights.subjectTaught.toFixed(
                                            2
                                          )}
                                          %
                                        </span>
                                        <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                                          Consider the subjects the teacher has
                                          previously taught.
                                        </span>
                                      </div>
                                    </div>
                                    <input
                                      id="SubjectTaught"
                                      name="SubjectTaught"
                                      type="checkbox"
                                      checked={isSubjectTaughtConsidered}
                                      className={` shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                                    ></input>
                                  </button>
                                  <div
                                    className={`p-8 py-2 w-full ${
                                      !isSubjectTaughtConsidered && "hidden"
                                    }`}
                                  >
                                    <Slider
                                      size="sm"
                                      color="secondary"
                                      label={
                                        <div className="flex gap-2 items-center">
                                          <span>Weights</span>
                                          <ResponsiveToolTip
                                            tooltipMessage="Weights are numerical values that determine the importance of different factors."
                                            tooltipTitle="What is Weights?"
                                          />
                                        </div>
                                      }
                                      step={1}
                                      maxValue={100}
                                      minValue={1}
                                      value={weights.subjectTaught}
                                      onChange={(value) => {
                                        setWeights({
                                          ...weights,
                                          subjectTaught: value
                                        });
                                      }}
                                      className=" w-full"
                                    />
                                  </div>
                                </div>
                                {/* Degree Relevance */}
                                <div className=" rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700">
                                  <button
                                    onClick={() => {
                                      setisDegreeConsidered(
                                        !isDegreeConsidered
                                      );
                                      if (!isDegreeConsidered) {
                                        setWeights({ ...weights });
                                      } else {
                                        ssetTotalWeights({
                                          ...totalWeights,
                                          degree: 0
                                        });
                                      }
                                    }}
                                    className="flex w-full items-center gap-x-3.5 py-2 px-3"
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-mortarboard-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917z" />
                                        <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466z" />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full flex flex-col text-secondary items-start justify-center">
                                        <span className="flex-1 text-small font-normal truncate">
                                          Degree{" "}
                                          {totalWeights.degree.toFixed(2)}%
                                        </span>
                                        <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                                          Assess the teacher’s educational
                                          qualifications and degrees.
                                        </span>
                                      </div>
                                    </div>
                                    <input
                                      id="PrioritizeMajorsSubjects"
                                      name="PrioritizeMajorsSubjects"
                                      type="checkbox"
                                      checked={isDegreeConsidered}
                                      className={` shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                                    ></input>
                                  </button>
                                  <div
                                    className={`p-8 py-2 w-full ${
                                      !isDegreeConsidered && "hidden"
                                    }`}
                                  >
                                    <Slider
                                      size="sm"
                                      color="secondary"
                                      label={
                                        <div className="flex gap-2 items-center">
                                          <span>Weights</span>
                                          <ResponsiveToolTip
                                            tooltipMessage="Weights are numerical values that determine the importance of different factors."
                                            tooltipTitle="What is Weights?"
                                          />
                                        </div>
                                      }
                                      step={1}
                                      maxValue={100}
                                      minValue={1}
                                      value={weights.degree}
                                      onChange={(value) => {
                                        setWeights({
                                          ...weights,
                                          degree: value
                                        });
                                      }}
                                      defaultValue={50}
                                      className=" w-full"
                                    />
                                  </div>
                                </div>
                                {/* Experience */}
                                <div className=" rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700">
                                  <button
                                    onClick={() => {
                                      setisExperienceConsidered(
                                        !isExperienceConsidered
                                      );
                                      if (!isExperienceConsidered) {
                                        setWeights({ ...weights });
                                      } else {
                                        ssetTotalWeights({
                                          ...totalWeights,
                                          experience: 0
                                        });
                                      }
                                    }}
                                    className="flex w-full items-center gap-x-3.5 py-2 px-3"
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-bar-chart-steps"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full flex flex-col text-secondary items-start justify-center">
                                        <span className="flex-1 text-small font-normal truncate">
                                          Experience{" "}
                                          {totalWeights.experience.toFixed(2)}%
                                        </span>
                                        <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                                          Evaluate the teacher’s relevant
                                          professional experience.
                                        </span>
                                      </div>
                                    </div>
                                    <input
                                      id="PrioritizeHigherGrades"
                                      name="PrioritizeHigherGrades"
                                      type="checkbox"
                                      checked={isExperienceConsidered}
                                      className={`shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                                    ></input>{" "}
                                  </button>
                                  <div
                                    className={`p-8 py-2 w-full ${
                                      !isExperienceConsidered && "hidden"
                                    }`}
                                  >
                                    <Slider
                                      size="sm"
                                      color="secondary"
                                      label={
                                        <div className="flex gap-2 items-center">
                                          <span>Weights</span>
                                          <ResponsiveToolTip
                                            tooltipMessage="Weights are numerical values that determine the importance of different factors."
                                            tooltipTitle="What is Weights?"
                                          />
                                        </div>
                                      }
                                      step={1}
                                      maxValue={100}
                                      minValue={1}
                                      value={weights.experience}
                                      onChange={(value) => {
                                        setWeights({
                                          ...weights,
                                          experience: value
                                        });
                                      }}
                                      className=" w-full"
                                    />
                                  </div>
                                </div>
                                {/* Preferrences */}
                                <div className=" rounded-lg  text-secondary hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700">
                                  <button
                                    onClick={() => {
                                      setisPreferencesConsidered(
                                        !isPreferencesConsidered
                                      );

                                      if (!isPreferencesConsidered) {
                                        setWeights({ ...weights });
                                      } else {
                                        ssetTotalWeights({
                                          ...totalWeights,
                                          preference: 0
                                        });
                                      }
                                    }}
                                    className="flex w-full items-center gap-x-3.5 py-2 px-3"
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-search-heart"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M6.5 4.482c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.69 0-5.018" />
                                        <path d="M13 6.5a6.47 6.47 0 0 1-1.258 3.844q.06.044.115.098l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1-.1-.115h.002A6.5 6.5 0 1 1 13 6.5M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11" />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full flex flex-col text-secondary items-start justify-center">
                                        <span className="flex-1 text-small font-normal truncate">
                                          Preference{" "}
                                          {totalWeights.preferences.toFixed(2)}%
                                        </span>
                                        <span className="w-full text-tiny text-foreground-500 text-left group-hover:text-current">
                                          Include the teacher’s preferred or
                                          specialized teaching areas.
                                        </span>
                                      </div>
                                    </div>
                                    <input
                                      id="PrioritizeHigherGrades"
                                      name="PrioritizeHigherGrades"
                                      type="checkbox"
                                      checked={isPreferencesConsidered}
                                      className={`shrink-0 border-gray-200 rounded text-secondary focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800`}
                                    ></input>
                                  </button>

                                  <div
                                    className={`p-8 py-2 w-full ${
                                      !isPreferencesConsidered && "hidden"
                                    }`}
                                  >
                                    <Slider
                                      size="sm"
                                      color="secondary"
                                      label={
                                        <div className="flex gap-2 items-center">
                                          <span>Weights</span>
                                          <ResponsiveToolTip
                                            tooltipMessage="Weights are numerical values that determine the importance of different factors."
                                            tooltipTitle="What is Weights?"
                                          />
                                        </div>
                                      }
                                      step={1}
                                      maxValue={100}
                                      minValue={1}
                                      value={weights.preference}
                                      onChange={(value) => {
                                        setWeights({
                                          ...weights,
                                          preference: value
                                        });
                                      }}
                                      defaultValue={50}
                                      className=" w-full"
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* Views */}
                              <div>
                                <div className="py-1 flex items-center gap-1">
                                  <span className="text-xs font-normal text-[#71717a]">
                                    Views
                                  </span>
                                  <ResponsiveToolTip
                                    tooltipIconColor="#71717a"
                                    tooltipTitle="What is Views ?"
                                    tooltipMessage="Views allow you to change how the output is displayed, enabling you to customize the presentation of data according to your preferences."
                                  />
                                </div>
                                <div className=" border-b-1 p-1">
                                  <button
                                    onClick={() => {
                                      setSelectedView("courses");
                                      // setIsPopOverOpen(false);
                                    }}
                                    className={`${
                                      selectedView === "courses" &&
                                      "bg-secondary-50"
                                    } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-journals"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2" />
                                        <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0" />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full flex flex-col items-start justify-center">
                                        <span
                                          id="react-aria3808636772-:r265:"
                                          className="flex-1 text-small font-normal truncate"
                                        >
                                          <span className=" flex h-[25px] items-center">
                                            Course{" "}
                                            <sup className=" text-secondary-600">
                                              {" "}
                                              Adjustable
                                            </sup>
                                          </span>
                                        </span>
                                        <span
                                          id="react-aria3808636772-:r266:"
                                          className="w-full text-tiny text-foreground-500 group-hover:text-current"
                                        >
                                          <span>
                                            View by individual Courses
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <button
                                    onClick={() => {
                                      setSelectedView("teachers");
                                      // setIsPopOverOpen(false);
                                    }}
                                    className={`${
                                      selectedView === "teachers" &&
                                      "bg-secondary-50"
                                    } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-people-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full flex flex-col items-start justify-center">
                                        <span className="flex-1 text-small font-normal truncate">
                                          Teachers
                                        </span>
                                        <span className="w-full text-tiny text-foreground-500 group-hover:text-current">
                                          View by specific Teachers
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  <button
                                    onClick={() => {
                                      setSelectedView("specific assignments");
                                      // setIsPopOverOpen(false);
                                    }}
                                    className={`${
                                      selectedView === "specificassignments" &&
                                      "bg-secondary-50"
                                    } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-list-columns-reverse"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M0 .5A.5.5 0 0 1 .5 0h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 .5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10A.5.5 0 0 1 4 .5m-4 2A.5.5 0 0 1 .5 2h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 4h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 6h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 8h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
                                        />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full flex flex-col items-start justify-center">
                                        <span className="flex-1 text-small font-normal truncate">
                                          Specific Assignments
                                        </span>
                                        <span className="w-full text-tiny text-foreground-500 group-hover:text-current">
                                          View by specific assigned subjects to
                                          teachers
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Tab>
                    <Tab title="Schedule Configs" className="p-0">
                      <Card className="shadow-none">
                        <CardBody className="">
                          <div className="max-h-[300px] shadow-none overflow-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-500 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
                            <div className={" cursor-default"}>
                              <div className="py-1">
                                <span className="text-xs font-normal text-[#71717a]">
                                  Subject Scheduling Presets
                                </span>
                              </div>
                              <button
                                onClick={() => {
                                  // setScheduleConfigs({
                                  //   ...ScheduleConfigs,
                                  //   scheduleConfig: "normalScheduling"
                                  // });
                                }}
                                className={`${
                                  ScheduleConfigs.scheduleConfig ===
                                    "normalScheduling" && "bg-secondary-50"
                                } cursor-default flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600  focus:outline-none  dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-calendar-check"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                  </svg>
                                </div>
                                <div className="flex flex-col">
                                  <div className="w-full flex flex-col items-start justify-center">
                                    <span
                                      id="react-aria1555630211-:r69:"
                                      className="flex-1 text-small font-normal truncate"
                                    >
                                      Normal Scheduling
                                    </span>
                                    <span
                                      id="react-aria1555630211-:r6a:"
                                      className="w-full  text-tiny text-left text-foreground-500 group-hover:text-current"
                                    >
                                      Every subject is scheduled to be taught
                                      every day throughout the academic week.
                                    </span>
                                  </div>
                                </div>
                              </button>
                              <button
                                onClick={() => {
                                  // setScheduleConfigs({
                                  //   ...ScheduleConfigs,
                                  //   scheduleConfig: "allSubject1h"
                                  // });
                                }}
                                className={`${
                                  ScheduleConfigs.scheduleConfig ===
                                    "allSubject1h" && "bg-secondary-50"
                                } cursor-default flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600  focus:outline-none  dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-calendar3"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                                  </svg>
                                </div>
                                <div className="flex flex-col">
                                  <div className="w-full flex flex-col items-start justify-center">
                                    <span
                                      id="react-aria1555630211-:r69:"
                                      className="flex-1 text-small font-normal truncate"
                                    >
                                      1 Hour per Subject Scheduling
                                    </span>
                                    <span
                                      id="react-aria1555630211-:r6a:"
                                      className="w-full text-tiny text-left text-foreground-500 group-hover:text-current"
                                    >
                                      Each subject is taught for 1 hour daily
                                    </span>
                                  </div>
                                </div>
                              </button>
                              <button
                                onClick={() => {
                                  // setScheduleConfigs({
                                  //   ...ScheduleConfigs,
                                  //   scheduleConfig: "Subject1Min"
                                  // });
                                }}
                                className={`${
                                  ScheduleConfigs.scheduleConfig ===
                                    "Subject1Min" && "bg-secondary-50"
                                } cursor-default flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600  focus:outline-none  dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                              >
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-calendar-week"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                  </svg>
                                </div>
                                <div className="flex flex-col">
                                  <div className="w-full flex flex-col items-start justify-center">
                                    <span
                                      id="react-aria1555630211-:r69:"
                                      className="flex-1 text-small font-normal truncate"
                                    >
                                      1 Hour Major Minimum Scheduling
                                    </span>
                                    <span
                                      id="react-aria1555630211-:r6a:"
                                      className="w-full text-tiny text-left text-foreground-500 group-hover:text-current"
                                    >
                                      Major subjects must be taught for at least
                                      1 hour daily, while other subjects can
                                      have variable scheduling.
                                    </span>
                                  </div>
                                </div>
                              </button>
                            </div>
                            <div className="m-0"></div>
                            {/* General Schedule Info */}
                            {/* General Schedule Info */}
                            <div className=" flex space-y-2 flex-col">
                              <div className="py-1">
                                <span className="text-xs font-normal text-[#71717a]">
                                  Schedule Time Configuration
                                </span>
                              </div>
                              <div className=" flex space-x-2">
                                <TimeInput
                                  className={` ${
                                    Errors.start &&
                                    "border border-red-300 rounded-xl"
                                  }`}
                                  color="warning"
                                  label="Start Time"
                                  value={ScheduleConfigs.start}
                                  onChange={(value) => {
                                    setScheduleConfigs({
                                      ...ScheduleConfigs,
                                      start: value
                                    });
                                  }}
                                />
                                <TimeInput
                                  className={` ${
                                    Errors.dismissal &&
                                    "border border-red-300 rounded-xl"
                                  }`}
                                  color="warning"
                                  label="Dismissal Time"
                                  value={ScheduleConfigs.dismissal}
                                  onChange={(value) => {
                                    setScheduleConfigs({
                                      ...ScheduleConfigs,
                                      dismissal: value
                                    });
                                  }}
                                />
                              </div>
                              <div>
                                <div className="pb-4">
                                  <div className="py-1">
                                    <span className="text-xs font-normal text-[#71717a]">
                                      Breaks and Lunches Configuration
                                    </span>
                                  </div>
                                  <button
                                    onClick={() => {
                                      setBLConfigs({
                                        ...BLConfigs,
                                        BLConfig: "default"
                                      });
                                    }}
                                    className={`${
                                      BLConfigs.BLConfig === "default" &&
                                      "bg-secondary-50"
                                    } cursor-default flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600  focus:outline-none focus:bg-secondary-50 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-border-all"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M0 0h16v16H0zm1 1v6.5h6.5V1zm7.5 0v6.5H15V1zM15 8.5H8.5V15H15zM7.5 15V8.5H1V15z" />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full flex flex-col items-start justify-center">
                                        <span
                                          id="react-aria1555630211-:r69:"
                                          className="flex-1 text-small font-normal truncate"
                                        >
                                          Default Break and Lunches
                                        </span>
                                        <span
                                          id="react-aria1555630211-:r6a:"
                                          className="w-full  text-tiny text-left text-foreground-500 group-hover:text-current"
                                        >
                                          Same breaks and lunches for each grade
                                          level
                                        </span>
                                      </div>
                                    </div>
                                  </button>
                                  {/* <button
                                    disabled={true}
                                    onClick={() => {
                                      setBLConfigs({
                                        ...BLConfigs,
                                        BLConfig: "alternate"
                                      });
                                    }}
                                    className={`${
                                      BLConfigs.BLConfig === "alternate" &&
                                      "bg-secondary-50"
                                    } flex w-full items-center gap-x-3.5 py-2 px-3 rounded-lg  text-secondary-600  dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700`}
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-border-center"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M.969 0H0v.969h.5V1h.469V.969H1V.5H.969zm.937 1h.938V0h-.938zm1.875 0h.938V0H3.78v1zm1.875 0h.938V0h-.938zM7.531.969V1h.938V.969H8.5V.5h-.031V0H7.53v.5H7.5v.469zM9.406 1h.938V0h-.938zm1.875 0h.938V0h-.938zm1.875 0h.938V0h-.938zm1.875 0h.469V.969h.5V0h-.969v.5H15v.469h.031zM1 2.844v-.938H0v.938zm6.5-.938v.938h1v-.938zm7.5 0v.938h1v-.938zM1 4.719V3.78H0v.938h1zm6.5-.938v.938h1V3.78h-1zm7.5 0v.938h1V3.78h-1zM1 6.594v-.938H0v.938zm6.5-.938v.938h1v-.938zm7.5 0v.938h1v-.938zM0 8.5v-1h16v1zm0 .906v.938h1v-.938zm7.5 0v.938h1v-.938zm8.5.938v-.938h-1v.938zm-16 .937v.938h1v-.938zm7.5 0v.938h1v-.938zm8.5.938v-.938h-1v.938zm-16 .937v.938h1v-.938zm7.5 0v.938h1v-.938zm8.5.938v-.938h-1v.938zM0 16h.969v-.5H1v-.469H.969V15H.5v.031H0zm1.906 0h.938v-1h-.938zm1.875 0h.938v-1H3.78v1zm1.875 0h.938v-1h-.938zm1.875-.5v.5h.938v-.5H8.5v-.469h-.031V15H7.53v.031H7.5v.469zm1.875.5h.938v-1h-.938zm1.875 0h.938v-1h-.938zm1.875 0h.938v-1h-.938zm1.875-.5v.5H16v-.969h-.5V15h-.469v.031H15v.469z" />
                                      </svg>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="w-full flex flex-col items-start justify-center">
                                        <span
                                          id="react-aria1555630211-:r69:"
                                          className="flex-1 text-small font-normal truncate"
                                        >
                                          Alternating Break and Lunches
                                        </span>
                                        <span
                                          id="react-aria1555630211-:r6a:"
                                          className="w-full text-tiny text-left text-foreground-500 group-hover:text-current"
                                        >
                                          Breaks and Lunches is sequeantially
                                          alternated to reduce canteen capacity
                                        </span>
                                      </div>
                                    </div>
                                  </button> */}
                                </div>
                                <div className="py-1">
                                  <span className="text-xs font-normal text-[#71717a]">
                                    Break and Lunch Time
                                  </span>
                                </div>
                                <div className="flex flex-col px-4 space-y-2">
                                  <div className="flex gap-2">
                                    <TimeInput
                                      className={` ${
                                        Errors.break &&
                                        "border border-red-300 rounded-xl"
                                      } w-full flex-1 `}
                                      color="warning"
                                      label="Break Time"
                                      value={BLConfigs.break}
                                      onChange={(value) => {
                                        setBLConfigs({
                                          ...BLConfigs,
                                          break: value
                                        });
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className={`${
                                        (parseInt(BLConfigs.totalBreak) > 60 ||
                                          parseInt(BLConfigs.totalBreak) < 5 ||
                                          !BLConfigs.totalBreak) &&
                                        "border-red-500 focus:border-red-500 focus:ring-red-500"
                                      } py-2 px-3 rounded-xl w-32 bg-warning-50 text-warning-600 flex-initial text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600`}
                                      required
                                      placeholder="Total mins"
                                      value={BLConfigs.totalBreak}
                                      onChange={(e) =>
                                        setBLConfigs({
                                          ...BLConfigs,
                                          totalBreak: e.target.value.replace(
                                            /\D/g,
                                            ""
                                          ) // Ensure only numeric characters are stored in state
                                        })
                                      }
                                      onKeyDown={(e) => {
                                        const isNumeric = /^\d$/.test(e.key);
                                        const isBackspaceOrDelete =
                                          e.key === "Backspace" ||
                                          e.key === "Delete";
                                        const isArrowKey =
                                          e.key.startsWith("Arrow");
                                        const isNavigationKey =
                                          e.key === "Tab" || e.key === "Enter";
                                        const isMaxLengthReached =
                                          e.target.value.length >= 2;

                                        // Allow numeric keys, backspace, delete, arrow keys, tab, and enter
                                        if (
                                          isNumeric ||
                                          isBackspaceOrDelete ||
                                          isArrowKey ||
                                          isNavigationKey
                                        ) {
                                          return;
                                        }

                                        // Prevent non-numeric inputs and check length constraints
                                        if (
                                          !isMaxLengthReached ||
                                          isBackspaceOrDelete
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="flex gap-2">
                                    <TimeInput
                                      className={` ${
                                        Errors.lunch &&
                                        "border  border-red-300 rounded-xl"
                                      } flex-1 `}
                                      color="warning"
                                      label="Lunch Time"
                                      value={BLConfigs.lunch}
                                      onChange={(value) => {
                                        setBLConfigs({
                                          ...BLConfigs,
                                          lunch: value
                                        });
                                      }}
                                    />
                                    <input
                                      type="text"
                                      className={`${
                                        parseInt(BLConfigs.totalLunch) <= 29 &&
                                        "border-red-500 focus:border-red-500 focus:ring-red-500"
                                      } py-2 px-3 rounded-xl w-32 bg-warning-50 text-warning-600 flex-initial text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600`}
                                      required
                                      placeholder="Total mins"
                                      value={BLConfigs.totalLunch}
                                      onChange={(e) =>
                                        setBLConfigs({
                                          ...BLConfigs,
                                          totalLunch: e.target.value.replace(
                                            /\D/g,
                                            ""
                                          ) // Ensure only numeric characters are stored in state
                                        })
                                      }
                                      onKeyDown={(e) => {
                                        const isNumeric = /^\d$/.test(e.key);
                                        const isBackspaceOrDelete =
                                          e.key === "Backspace" ||
                                          e.key === "Delete";
                                        const isArrowKey =
                                          e.key.startsWith("Arrow");
                                        const isNavigationKey =
                                          e.key === "Tab" || e.key === "Enter";
                                        const isMaxLengthReached =
                                          e.target.value.length >= 2;

                                        // Allow numeric keys, backspace, delete, arrow keys, tab, and enter
                                        if (
                                          isNumeric ||
                                          isBackspaceOrDelete ||
                                          isArrowKey ||
                                          isNavigationKey
                                        ) {
                                          return;
                                        }

                                        // Prevent non-numeric inputs and check length constraints
                                        if (
                                          !isMaxLengthReached ||
                                          isBackspaceOrDelete
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Tab>
                  </Tabs>
                </div>
              </ModalBody>
              <ModalFooter className="pt-0">
                <div className="flex w-full">
                  <div className=" flex-1 flex items-center">
                    {configs?.department &&
                    department?.[configs.department]?.Needed >
                      department?.[configs.department]?.Total &&
                    department.totalNeeded < department.totalCurrent ? (
                      <div className="flex gap-2 text-sm text-warning-500 items-center">
                        <div className=" w-4 h-4">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-exclamation-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                          </svg>
                        </div>
                        <div>
                          {" "}
                          Enough total teachers available, though fewer in the
                          department. <b>Scheduling may take longer</b>.
                        </div>
                      </div>
                    ) : (
                      department?.[configs.department]?.Needed >
                        department?.[configs.department]?.Total &&
                      department.totalNeeded >= department.totalCurrent && (
                        <div className="flex gap-2 text-sm text-danger-600 items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-exclamation-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                          </svg>
                          Unsuffecient Teacher
                        </div>
                      )
                    )}
                  </div>
                  <div className=" flex-initial">
                    <Button color="danger" variant="light" onPress={onClose}>
                      Close
                    </Button>
                    <Button
                      color="secondary"
                      isDisabled={
                        !configs?.department || // No department selected
                        !configs?.type || // No type selected
                        factors.length <= 0 || // No factors available
                        (isAdvisorPriority && !AdvisorStatus) || // Advisor status not set when priority is enabled
                        (isAdvisorPriority &&
                          Object.keys(configs.advisorySubject).length <= 0) || // Advisory subject missing
                        Errors.start !== "" || // Errors in start time
                        Errors.dismissal !== "" || // Errors in dismissal time
                        Errors.break !== "" || // Errors in break time
                        Errors.lunch !== "" || // Errors in lunch time
                        error !== "" || // General error check
                        ScheduleConfigs.academicYear === "" || // Academic year is not set
                        (configs.department === "SHS" &&
                          parseInt(configs.sem) !== 1 &&
                          parseInt(configs.sem) !== 2) ||
                        // Only enable if department is SHS and semester is 1 or 2
                        (configs?.department &&
                          department?.[configs?.department]?.Needed >
                            department?.[configs?.department]?.Total &&
                          department.totalNeeded >= department.totalCurrent)
                      }
                      onPress={() => {
                        onClose();
                        navigate(
                          `generate/${configs?.department}/${configs?.type}`,
                          {
                            state: {
                              isAdvisorPriority: isAdvisorPriority,
                              schedType: ScheduleConfigs.scheduleConfig,
                              advisors: advisors,
                              advisorySubject: configs.advisorySubject.$id,
                              view: selectedView,
                              sem: configs.sem,
                              suitabilityFactors: factors,
                              weights: weights,
                              totalSuitabilityFactors: totalWeights,
                              assignmentPriority: AssignmentConfigs,
                              priority: sortPriority,
                              scheduleConfig: {
                                BLConfig: BLConfigs,
                                ScheduleConfigs: ScheduleConfigs
                              }
                            }
                          }
                        );
                        console.log(configs);
                      }}
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
