import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  Button,
  Pagination,
  Skeleton,
  Spinner,
  Tooltip
} from "@nextui-org/react";
import SectionSchedule from "../components/renders/SectionSchedule";
import { useParams } from "react-router-dom";
import Error from "../../../../Components/ErrorIndicator";
import axios from "axios";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import { Time } from "@internationalized/date";
import ScheduleOptions from "../components/OptionDropDown";
import OptionSched from "../components/OptionSchedules";
import DefaultViewSection from "../components/sectionViews/defaultView";
import ScheduleView from "../components/sectionViews/ScheduleView";
import { ByCourseView } from "../../../../utils/helpers/ScheduleSortings";
export default function CreateSchedules({
  AssignedTeachers = [],
  isCreateSched = false,
  Configurations,
  currentStep,
  isFinishable,
  confirmSchedule
}) {
  /**
   *
   * RENDER ALL ASSIGNED TEACHERS IN A DEPARTEMENT
   *
   */
  const { dept, schedtype } = useParams();
  const { SchedulingServer } = useAuth();

  const [MainError, setMainError] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  //Paginations
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 2; // Number of items per page
  //UNUSED
  const [isTotalTeacherLoading, setisTotalTeacherLoading] = useState(false);
  const [isTeacherLoading, setisTeacherLoading] = useState(false);

  //Schedule STates
  const [isRequiredDataAvailable, setIsRequiredDataAvailable] = useState(false);
  const [isScheduleCreationDone, setIsScheduleCreationDone] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [Configs, setConfigs] = useState({
    department: dept,
    range: "",
    type: schedtype
  });

  const [BLConfigs, setBLConfigs] = useState(
    Configurations?.BLConfig || {
      BLConfig: "default", // default or alternate
      break: new Time(10), //time,
      totalBreak: 15,
      lunch: new Time(12), //time
      totalLunch: 30
    }
  );

  useEffect(() => {
    
  }, [isLoading]);

  const [ScheduleConfigs, setScheduleConfigs] = useState(
    Configurations?.ScheduleConfigs || {
      scheduleConfig: "normalScheduling",
      start: new Time(7),
      dismissal: new Time(15)
    }
  );

  const [AdvisorConfigs, setAdvisorConfigs] = useState({
    isAdvisorPriority: Configurations?.isAdvisorPriority || false,
    advisors: Configurations?.advisors || {},
    advisorySubject: Configurations?.advisorySubject || ""
  });

  const [Courses, setCourses] = useState([]);

  useEffect(() => {
    switch (dept.toLocaleLowerCase()) {
      case "pre":
        Configs.department = "Pre-School";
        Configs.range = "Nursery - Kinder 2";
        break;
      case "elem":
        Configs.department = "Elementary";
        Configs.range = "Grade 1 - 6";
        break;
      case "jhs":
        Configs.department = "Junior High-School";
        Configs.range = "Grade 6 - 10";
        break;
      case "shs":
        Configs.department = "Senior High-School";
        Configs.range = "Grade 11 - 12";
        break;
      default:
        break;
    }
  }, []);

  //CHECKS REQUIRED DATA IF AVAILABLE
  useEffect(() => {
    if (AssignedTeachers.length > 0) {
      setIsRequiredDataAvailable(true);
    } else {
      setIsRequiredDataAvailable(false);
    }
  }, [AssignedTeachers]);
  // console.log(ByCourseView(AssignedTeachers, [], [])[0]);

  //CALL BACKEND SERVER TO CREATE SCHEDULE
  useEffect(() => {
    async function CreateSchedules() {
      try {
        setisLoading(true);

        if (isRequiredDataAvailable) {
         
          //Arrange Needed Data for Schedule Creation
          const body = {
            Configs: {
              AdvisorConfigs: AdvisorConfigs,
              BLConfig: BLConfigs,
              ScheduleConfig: ScheduleConfigs
            },
            AssignedTeachers: ByCourseView(AssignedTeachers, [], []),
            dept: dept
          };
          let url = "";
        
          switch (dept) {
            case "Elem":
              url = `${SchedulingServer}/schedule/create/weeklyschedule/v3`;
              break;
            case "JHS":
              url = `${SchedulingServer}/schedule/create/weeklyschedule/v3`;
              break;
            case "Pre":
              url = `${SchedulingServer}/schedule/create/weeklyschedule/v3`;
              break;
            case "SHS":
              url = `${SchedulingServer}/schedule/create/weeklyschedule/v3`;
              break;
            default:
              setMainError("Department is missing");
              break;
          }
          //Request Schedule From Backend Server
          const ScheduleResponse = await axios.post(url, body);
    
          setCourses(ScheduleResponse.data);
        } else {
          setMainError("Required Data Must be Loaded First");
        }

        if (isFirstRender) {
          setIsFirstRender(false);
        }
      } catch (error) {
        setMainError(error.message);
      } finally {
        setisLoading(false);
        setIsRefresh(false);
      }
    }
    // && isFirstRender
    if (
      (!isScheduleCreationDone && currentStep === 1 && isFirstRender) ||
      isRefresh
    ) {
      
      CreateSchedules();
    }
  }, [
    isCreateSched,
    isScheduleCreationDone,
    isRequiredDataAvailable,
    SchedulingServer,
    isRefresh,
    currentStep
  ]);

  function ConfigUpdater(BL, Sched) {
    setIsRefresh(true);
    setBLConfigs(BL && Object.keys(BL).length > 0 ? BL : BLConfigs);
    setScheduleConfigs(
      Sched && Object.keys(Sched).length > 0 ? Sched : ScheduleConfigs
    );
  }

  useEffect(() => {
    // Calculate total pages based on the total length of Courses and itemsPerPage
    setTotalPages(Math.ceil(Courses.length / itemsPerPage));
  }, [Courses.length, itemsPerPage]);

  const getCoursesForCurrentPage = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;

    const coursePerPage =
      Courses && Courses.length > 0
        ? Courses.slice(startIndex, startIndex + itemsPerPage)
        : [];
    return coursePerPage;
  };

  //This handles the finish button if its okay to proceed
  useEffect(() => {
    const isEachSectionHaveSchedule = Courses.every(
      (course) => course.Schedule && Object.keys(course.Schedule).length > 0
    );
    isFinishable(!isLoading && Courses && isEachSectionHaveSchedule);
  }, [Courses, isLoading, isFinishable]);

  //This gives the schedules to the parent when schedules are created
  useEffect(() => {
 
    confirmSchedule(Courses);
  }, [Courses, isScheduleCreationDone, confirmSchedule]);

  return (
    <div className=" w-full">
      <Error errorMessage={MainError} reset={setMainError} />
      <div class="max-w-[85rem] px-4 pt-0 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div class="grid items-center lg:grid-cols-12 gap-6 lg:gap-12">
          <div class="lg:col-span-4">
            <div class="lg:pe-6 xl:pe-12">
              <p class="text-4xl font-bold leading-10 text-[#72539bcc]">
                {Configs.department}
              </p>
              <p class="mt-2 sm:mt-3 text-gray-500 dark:text-neutral-500">
                {Configs.range}
              </p>
            </div>
          </div>

          <div class="lg:col-span-8 relative lg:before:absolute lg:before:top-0 lg:before:-start-12 lg:before:w-px lg:before:h-full lg:before:bg-gray-200 lg:before:dark:bg-neutral-700">
            <div class="grid gap-6 grid-cols-2 md:grid-cols-4 lg:grid-cols-3 sm:gap-8">
              <div>
                {isLoading ? (
                  <Spinner size="md" color="secondary"></Spinner>
                ) : (
                  <p class="text-3xl font-semibold text-[#72539bcc]">6</p>
                )}

                <p class="mt-1 text-gray-500 dark:text-neutral-500">
                  Courses to Create Schedule
                </p>
              </div>

              <div>
                {isLoading ? (
                  <Spinner size="md" color="secondary"></Spinner>
                ) : (
                  <p class="text-3xl font-semibold text-[#72539bcc]">12</p>
                )}
                <p class="mt-1 text-gray-500 dark:text-neutral-500">
                  Total Schedules created in a section
                </p>
              </div>
              <div>
                {isTotalTeacherLoading ? (
                  <Spinner size="md" color="secondary"></Spinner>
                ) : (
                  <p class="text-3xl font-semibold text-[#72539bcc]">12</p>
                )}
                <p class="mt-1 text-gray-500 dark:text-neutral-500">
                  Total Teacher in Elem Department
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Options or configurations */}
      <div className="relative flex flex-col sm:flex-row py-2 sticky p-2 my-2 top-3 z-50 right-2 justify-between">
        {/* Background with opacity */}
        <div className="absolute inset-0 bg-secondary rounded-lg opacity-80 z-[-1]"></div>

        {/* Your content */}
        <div className=" flex-1">
          <Pagination
            isDisabled={isLoading || isScheduleCreationDone}
            color="secondary"
            showControls
            total={totalPages}
            page={currentPage}
            onChange={setCurrentPage}
            classNames={{
              prev: " bg-secondary-400",
              item: "bg-secondary-500 text-white",
              next: "bg-secondary-400",
              cursor: "bg-[#ededed]  text-black"
            }}
          />
        </div>
        <div className=" flex h-full items-center gap-2">
          <Tooltip
            className=" bg-secondary-600 text-white"
            content="Re-Arrange Schedule"
          >
            <Button
              isIconOnly
              isDisabled={isLoading}
              onClick={() => {
                setIsRefresh(true);
              }}
              className=" bg-secondary-600 text-white justify-center items-center rounded-full flex p-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
              </svg>
            </Button>
          </Tooltip>

          <div className=" hidden sm:flex sm:flex-initial">
            <OptionSched
              loading={isLoading}
              isSchedDone={isScheduleCreationDone}
              data={{ BLConfigs, ScheduleConfigs }}
              updateConfigs={ConfigUpdater}
            />
          </div>
        </div>
      </div>{" "}
      <div className="flex flex-col gap-2">
        {isLoading ? (
          <Skeleton className=" w-full h-[400px] rounded-md bg-secondary-200"></Skeleton>
        ) : (
          <>
            {Courses &&
            Courses.length > 0 &&
            getCoursesForCurrentPage().length > 0 ? (
              getCoursesForCurrentPage().map((course) => (
                <div
                  class="hs-accordion-group space-y-3 w-full"
                  data-hs-accordion-always-open=""
                >
                  <Skeleton
                    className=" rounded-lg "
                    isLoaded={!isTeacherLoading}
                  >
                    <div
                      class=" hs-accordion bg-white rounded-lg  active "
                      id="hs-basic-with-title-and-arrow-stretched-heading-one"
                    >
                      <button
                        disabled={isTeacherLoading}
                        class="hs-accordion-toggle bg-[#72539bcc] text-white px-2 py-3 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-500 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                        aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one"
                      >
                        {course?.courseName || "GRADE LEVEL"} |{" "}
                        {course?.sectionName || "SECTION"}
                        <svg
                          class="hs-accordion-active:hidden block size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="m6 9 6 6 6-6"></path>
                        </svg>
                        <svg
                          class="hs-accordion-active:block hidden size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="m18 15-6-6-6 6"></path>
                        </svg>
                      </button>
                      <div
                        id="hs-basic-with-title-and-arrow-stretched-collapse-one"
                        class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                        aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one"
                      >
                        <div className="p-2">
                          <div
                            class="hs-accordion-group space-y-3 w-full"
                            data-hs-accordion-always-open=""
                          >
                            <ScheduleView schedules={course.Schedule} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Skeleton>
                </div>
              ))
            ) : (
              <div>No Created Schedule</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
