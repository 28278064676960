import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Spinner,
  Skeleton,
  Avatar,
  Card,
  CardFooter,
  Image
} from "@nextui-org/react";

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import RandomSlotView from "./components/sectionViews/randomSlotView";
import RemoveSchedule from "./components/modal/RmoveSched";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";

export default function PerDepartmentView({
  department,
  AcademicYear,
  isOld = false,
  groupId
}) {
  const { SchedulingServer } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [refresher, setIsRefresher] = useState(false);
  const [MainError, setMainError] = useState("");

  const [SectionsFetched, setSectionsFetched] = useState(false);
  const [sections, setSections] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const [groupScheduleId, setGroupScheduleId] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (selectedSchedule) {
      onOpen(); // Open modal when a schedule is selected
    }
  }, [selectedSchedule, onOpen]);

  const fetchCalled = useRef(false); // Ref to track if fetch has been called

  // Effect to fetch sections
  useEffect(() => {
    async function getSectionSchedules() {
      try {
        setIsLoading(true);
        console.log(department.code, AcademicYear, isOld, groupId);

        const sectionResponse = await axios.get(
          `${SchedulingServer}/schedule/sections/active`,
          {
            params: {
              dept: department.code,
              academicYear: AcademicYear,
              isOld: isOld,
              id: groupId
            }
          }
        );
        console.log("isold :", sectionResponse);
        setSections(sectionResponse.data.sections);
        setGroupScheduleId(sectionResponse.data.groupScheduleId);
        setSectionsFetched(true);
      } catch (error) {
        setMainError(
          error?.response?.data?.error
            ? error.response.data.error
            : error.message
        );
      } finally {
        setIsLoading(false);
      }
    }

    // Only trigger fetch if not called before or refresher changed
    if (department && groupId && AcademicYear && !fetchCalled.current) {
      console.log("FETCHING SECTIONS");
      fetchCalled.current = true;
      getSectionSchedules();
    }
  }, [department, groupId, AcademicYear, isOld, refresher, SchedulingServer]);

  // Effect to reset fetchCalled when refresher changes
  useEffect(() => {
    fetchCalled.current = false; // Reset fetchCalled so section fetch can re-run
  }, [refresher]);

  const handleRealtimeMessage = (message) => {
    console.log("Received Realtime message:", message);
    setIsRefresher((prev) => !prev); // Toggle refresher to trigger re-fetch
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_GROUP_SCHED,
    handleRealtimeMessage
  );

  return (
    <div className="rounded-md w-full bg-white">
      {/* Title */}
      <div className="flex justify-between w-full items-center bg-[#72539bcc] rounded-md p-2">
        <div className="text-white font-semibold">{department.name}</div>
        <div>
          {sections && sections.length > 0 && !isOld ? (
            <RemoveSchedule
              groupId={groupScheduleId}
              department={department}
              reset={setSections}
            />
          ) : (
            <div className=" size-10"></div>
          )}
        </div>
      </div>

      {/* Body */}
      {isLoading ? (
        <div className="p-2">
          <Skeleton className=" w-full h-20  rounded-md"></Skeleton>
        </div>
      ) : (
        <>
          {sections && sections.length > 0 ? (
            <div className="w-full p-2 my-2 h-[280px] overflow-y-hidden overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-secondary-400 dark:[&::-webkit-scrollbar-track]:bg-slate-700  dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ">
              <div className="inline-flex space-x-2 h-full">
                {sections.map((section) => (
                  <SectionSchedules
                    key={section.sectionId}
                    sectionData={section}
                    isSelected={
                      selectedSchedule?.sectionId === section.sectionId
                    }
                    select={setSelectedSchedule}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="p-4 text-center rounded-b-md">No schedule yet</div>
          )}
        </>
      )}

      {/* Modal */}
      <ScheduleShower
        isOpen={isOpen}
        onClose={onClose}
        dept={department}
        groupId={groupScheduleId}
        selectedSchedule={selectedSchedule}
        reset={setSelectedSchedule}
        server={SchedulingServer}
      />
    </div>
  );
}

function SectionSchedules({ sectionData, isSelected, select }) {
  return (
    <div
      onClick={() => select(sectionData)}
      className={`relative w-[280px] cursor-pointer h-full rounded-lg border shadow-lg  overflow-hidden transition-transform duration-300 transform hover:scale-105 ${
        isSelected ? "border-4 border-blue-500" : ""
      }`}

      // className={`  rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105`}
    >
      <Image
        removeWrapper
        alt="Card background"
        className="z-0 w-full h-full object-cover"
        src={require("../../../Img/BSA.jpg")}
      />
      <div className="absolute bg-[#e5dbf9]/90 bottom-0 w-full h-full z-10 rounded-lg">
        <div className=" relative p-4 flex flex-col justify-end w-full h-full">
          {/* <div className={` absolute top-2 right-2 text-secondary-600 `}>
            Academicyear
          </div> */}
          <div className=" flex flex-col justify-start text-start w-full">
            <div className=" text-xl flex items-center  gap-1 text-[#4a306d] font-semibold mb-1">
              <Avatar
                src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
                className="w-5 h-5 text-tiny"
              />
              <div> {sectionData.courseName} </div>
            </div>
            <div className="text-base text-[#7d5ca3]">
              {sectionData.sectionName}
            </div>
          </div>
        </div>
        {/* <div className=" text-medium flex flex-col items-start text-black/90">
          <span>{user?.accountType}</span>
          <span className="">
            {user?.Username}
            {user?.student?.firstName}{" "}
            {user?.student?.lastName}
          </span>
        </div>
        <p className="text-tiny text-white/90">
          {user?.role}
          {user.student && (
            <>Student | {user?.course?.courseName}</>
          )}
        </p> */}
      </div>
    </div>
    // <div
    //   onClick={() => select(sectionData)}
    //   className={`relative w-[280px] cursor-pointer h-full rounded-lg border shadow-lg bg-red-300 overflow-hidden transition-transform duration-300 transform hover:scale-105 ${
    //     isSelected ? "border-4 border-blue-500" : ""
    //   }`}
    // >
    //   <div className="absolute w-full h-full">
    //     <img
    //       src={require("../../../Img/BSA.jpg")}
    //       className="w-full h-full object-cover rounded-t-lg"
    //       alt="Course Images"
    //     />
    //   </div>
    //   <div className="absolute bottom-0 left-0 w-full h-[60px] flex items-center p-3 bg-[#72539bcc] rounded-lg">
    //     <span className="text-white font-semibold text-sm">
    //       {sectionData.courseName} | {sectionData.sectionName}
    //     </span>
    //   </div>
    // </div>
  );
}

function ScheduleShower({
  isOpen,
  dept,
  groupId,
  onClose,
  selectedSchedule,
  reset,
  server
}) {
  const closeAndClear = () => {
    reset(null); // Clear the selected schedule on close
    onClose(); // Close the modal
    setSections([]);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState("");
  const [sections, setSections] = useState([]);
  useEffect(() => {
    async function getSchedule() {
      try {
        setIsLoading(true);
        const sectionResponse = await axios.get(`${server}/schedule/section`, {
          params: {
            ...selectedSchedule,
            department: dept.name,
            groupId: groupId
          }
        });
        setSections(sectionResponse.data);
        console.log("oys", sectionResponse.data);
      } catch (error) {
        setMainError(
          error?.response?.data?.error
            ? error.response.data.error
            : error.message
        );
      } finally {
        setIsLoading(false);
      }
    }
    if ((selectedSchedule, groupId)) {
      getSchedule();
    }
  }, [selectedSchedule, groupId]);

  return (
    <>
      <Modal
        size="5xl"
        className=" max-h-[650px]"
        isOpen={isOpen}
        onOpenChange={closeAndClear}
        hideCloseButton
      >
        <ModalContent>
          {(closeAndClear) => (
            <>
              {/* Wrap the printable area */}
              <ModalHeader className="flex text-white bg-secondary-500 flex-col gap-1">
                {selectedSchedule?.courseName} | {selectedSchedule?.sectionName}
              </ModalHeader>
              <ModalBody className="flex items-center overflow-auto">
                {isLoading ? (
                  <Spinner>Loading...</Spinner>
                ) : (
                  <RandomSlotView schedule={sections} />
                )}

                {/* You can add more details related to selectedSchedule here */}
              </ModalBody>
              {/* Footer buttons (Close and Print) */}
              <div className="non-printable">
                <ModalFooter className="pt-0">
                  <Button
                    color="danger"
                    variant="light"
                    onPress={closeAndClear}
                  >
                    Close
                  </Button>
                  {/* <Button
                    onPress={() => {
                      window.print();
                    }}
                  >
                    Print
                  </Button> */}
                </ModalFooter>
              </div>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
