import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Tabs,
  Tab,
  Card,
  CardBody,
  CardHeader,
  Select,
  SelectItem
} from "@nextui-org/react";
import Edit from "../../../../../Components/modals/Edit";
import {
  SubjectCard,
  SubjectCardSkeleton
} from "../../../../../Components/modals/Subjectcard";
import AddSubject from "../../actions/AddSubject";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Sections from "../../../../../Components/sections";
import { CardRender } from "../../../../../Components/Cards/CardRender";
import AddSection from "../../actions/AddSection";
import SubjectDetails from "./SectionDetails";
import SectionDetails from "./SectionDetails";
import AssignedEnrolledStudentTotal from "../renders/TotalAssignedEnroledstudent";
import SubjectsInCourse from "../renders/SubjectsInCourse";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";
import Sectioncard from "../renders/Sectioncard";

export default function CourseDetails({ course, refresher, lrefresher }) {
  const { server, DataServer, role } = useAuth();

  //Subject CHild
  const [subjectisloading, setsubjectisloading] = useState(false);

  const [activetab, setactivetab] = useState("subject");
  const [Refresh, setRefresh] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isClose, setisClose] = useState(false);
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [Course, setCourse] = useState({});
  const [Subjects, setSubjects] = useState([]);
  const [courseSubjects, setCourseSubjects] = useState([]);
  const [sectioncourse, setsectioncourse] = useState([]);
  const [Section, setSection] = useState({});

  const [subjectContentHeight, setSubjectContentHeight] = useState("auto");
  const [sectionContentHeight, setSectionContentHeight] = useState("auto");
  const subjectContentRef = useRef(null);
  const sectionContentRef = useRef(null);

  useEffect(() => {
    console.log(Subjects?.length, Subjects);
  }, [Subjects]);

  //Every changes of the course by the raltime changes will change the Data in the modal
  useEffect(() => {
    setCourse(course);
  }, [course]);

  //Everytime the loading stop, it sets the Modal closer to false
  useEffect(() => {
    setisClose(false);
  }, [isLoading]);

  useEffect(() => {
    if (activetab === "subject" && subjectContentRef.current) {
      setSubjectContentHeight(subjectContentRef.current.offsetHeight + "px");
    } else if (activetab === "section" && sectionContentRef.current) {
      setSectionContentHeight(sectionContentRef.current.offsetHeight + "px");
    }
  }, [activetab]);

  //Gets the section and course of the specific course
  useEffect(() => {
    if (course && Object.keys(course)?.length !== 0) {
      async function fetchsubj() {
        try {
          setisLoading(true);

          const sec = await axios.get(`${server}/v1/course/sections`, {
            params: { CourseId: course.$id }
          });
          Course.section = sec.data;
          setsectioncourse(sec.data);
        } catch (error) {
          // Handle any errors
        } finally {
          setisLoading(false);
        }
      }
      fetchsubj();
    }
  }, [course, Refresh]);

  //Semester selector
  const [semester, setSemester] = useState("0");

  const handleSelectionChange = (e) => {
    setSemester(e.target.value);
  };

  useEffect(() => {
    console.log("main:", Course?.$id);
  }, [Course]);

  //Handles changing of tabs
  const handleTabSelectionChange = (newTabKey) => {
    setactivetab(newTabKey);
  };

  //rerender subjects
  const refreshsubjects = () => {
    setRefresh(!Refresh);
  };

  //Rerender parent
  const refreshcoursedetails = () => {
    setRefresh(!Refresh);
    lrefresher();
  };

  //Handle selected section
  const handleSectiondetails = (data) => {
    setSection(data);
    document.querySelector("#Sectiondetails").click();
  };

  const HandleRealtimeChanges = (changes) => {
    if (
      changes?.payload?.course?.$id === course.$id ||
      changes.payload.sectionName
    ) {
      refreshsubjects();
      setisClose(true);
    }

    //COurse refresh
    if (changes?.payload?.$id === course.$id) {
      course = changes.payload;
      setCourse(changes.payload);
    }

    console.log(changes);
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_SECTION,
    HandleRealtimeChanges
  );
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_SUBJECT,
    HandleRealtimeChanges
  );
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_COURSE,
    HandleRealtimeChanges
  );
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ENROLLMENT,
    HandleRealtimeChanges
  );

  useEffect(() => {
    console.log("loading:", subjectisloading);
  }, [subjectisloading]);

  function CloseParent() {
    console.log("CLOSED PARENT");
    onClose();
  }

  return (
    <>
      <button
        id="CourseDetailBtn"
        className="bg-[#FFDE59] hidden opacity-100 rounded-lg p-3 py-2 text-black hover:bg-[#fcd84b] text-sm"
        onClick={onOpen}
      >
        View
      </button>
      <Modal
        backdrop="opaque"
        isOpen={isOpen}
        size="4xl"
        onOpenChange={onOpenChange}
      >
        <ModalContent className=" font-poppins">
          {(onClose) => (
            <>
              <ModalHeader className="flex gap-4 items-center flex-wrap pb-1 ">
                <span className=" font-bold text-2xl text-secondary">
                  {Course?.courseName}
                </span>

                {/* Only show edit button to admin roles */}
                {role.includes("Adminhead") && (
                  <Edit
                    Title={"Course"}
                    CurrentData={Course}
                    Refresh={refresher}
                    closeParent={CloseParent}
                  />
                )}

                <Tabs
                  color={"secondary"}
                  aria-label="Tabs colors"
                  radius="full"
                  onSelectionChange={handleTabSelectionChange}
                >
                  <Tab key="subject" title="Subjects" />
                  <Tab key="section" title="Sections" />
                </Tabs>

                {/* Gets the total enrolled stutend to the specific course */}
                <AssignedEnrolledStudentTotal
                  courseid={course}
                  isrefreshed={Refresh}
                  refresher={refreshcoursedetails}
                />
              </ModalHeader>
              <ModalBody className="p-2 sm:px-6">
                <div className="flex justify-between flex-initial items-center sticky top-0 bg-white z-10">
                  <div className=" flex items-center gap-2">
                    {/* pang senior high */}
                    {course.gradeLevel > 10 && (
                      <Select
                        size="sm"
                        disallowEmptySelection
                        selectedKeys={[semester]}
                        isDisabled={subjectisloading}
                        onChange={handleSelectionChange}
                        label="Semester"
                        className="min-w-[120px] max-w-[120px]"
                      >
                        <SelectItem key={"0"}>All</SelectItem>
                        <SelectItem key={"1"}>1st Sem</SelectItem>
                        <SelectItem key={"2"}>2nd Sem</SelectItem>
                      </Select>
                    )}
                    <div className="flex items-center gap-1 font-medium text-lg">
                      <span className=" capitalize">{activetab + "s "}</span>
                      <span className="bg-yellow-200 rounded-full size-7 flex justify-center items-center text-sm">
                        {Subjects?.length}
                      </span>
                    </div>
                  </div>
                  {role.includes("Adminhead") && (
                    <>
                      {activetab === "subject" ? (
                        <>
                          {(course.gradeLevel < 10 ||
                            (semester !== "0" && course.gradeLevel > 10)) && (
                            <AddSubject
                              coursedata={Course}
                              subjectsdata={Subjects}
                              allSubjects={courseSubjects}
                              refresh={refreshsubjects}
                              sem={semester}
                              subjectstate={subjectisloading}
                            />
                          )}
                        </>
                      ) : (
                        <AddSection
                          reload={refreshsubjects}
                          courseid={Course.$id}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="flex w-full flex-col max-h-[300px] overflow-auto">
                  <div
                    className="transition-height ease-in-out min-h-[200px] max-h-[500px]"
                    style={{
                      height:
                        activetab === "subject"
                          ? subjectContentHeight
                          : sectionContentHeight
                    }}
                  >
                    <Tabs
                      selectedKey={activetab}
                      className="hidden flex-1"
                      aria-label="Options"
                    >
                      <Tab key="subject" title={"Subjects"}>
                        {course.gradeLevel > 10 ? (
                          //SHS view
                          <SubjectsInCourse
                            key={course.$id}
                            courseId={course?.$id}
                            courseName={Course?.courseName}
                            updatesubjects={setSubjects}
                            updateCourseSubjects={setCourseSubjects}
                            refresher={Refresh}
                            loadingstate={setsubjectisloading}
                            sem={semester}
                            dept={course?.department}
                          />
                        ) : (
                          //default view
                          <SubjectsInCourse
                            key={course.$id}
                            courseId={course?.$id}
                            refresher={Refresh}
                            courseName={Course?.courseName}
                            updatesubjects={setSubjects}
                            dept={course?.department}
                          />
                        )}
                      </Tab>
                      <Tab key="section" title="Sections">
                        <Card>
                          <CardBody
                            ref={sectionContentRef}
                            className="grid p-3 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  overflow-auto flex-1 "
                          >
                            {isLoading ? (
                              <>
                                <Sectioncard loadingstate={isLoading} />
                                <Sectioncard loadingstate={isLoading} />
                                <Sectioncard loadingstate={isLoading} />
                                <Sectioncard loadingstate={isLoading} />
                                <Sectioncard loadingstate={isLoading} />
                              </>
                            ) : (
                              <>
                                {sectioncourse && !sectioncourse?.length > 0 ? (
                                  <span>No section yet</span>
                                ) : (
                                  <>
                                    {sectioncourse &&
                                      sectioncourse.map((section) => (
                                        <span
                                          onClick={() => {
                                            handleSectiondetails(section);
                                          }}
                                          key={section.$id}
                                        >
                                          <Sectioncard
                                            data={section}
                                            coursedata={course}
                                            loadingstate={isLoading}
                                          />
                                        </span>
                                      ))}
                                  </>
                                )}
                              </>
                            )}
                          </CardBody>
                          <SectionDetails
                            data={Section}
                            courseData={Course}
                            refresher={refreshcoursedetails}
                            ModalCloseState={isClose}
                          />
                        </Card>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
