import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../utils/AppwriteConfig/AuthContext";
import Error from "../../../../../Components/ErrorIndicator";
import {
  birthdateformat,
  formatTime,
} from "../../../../../utils/helpers/stringformat";
import Logsskeleton from "../../../../../Components/skeleton/Logs";
import useRealtimeSubscription from "../../../../../utils/AppwriteConfig/RealtimeServices";

export default function AnnouncementRender({ currentdate }) {
  const { server, user, serverend } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [isRefresh, setisRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [Logs, setLogs] = useState([]);

  useEffect(() => {
    async function GetLogs() {
      try {
        setisLoading(true);
        const Logs = await axios.get(`${server}/v1/logs`, {
          params: { date: currentdate },
        });

        setLogs(Logs.data.documents);
      } catch (e) {
        console.log(e);
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }
    }

    GetLogs();
  }, [currentdate, isRefresh]);

  //Get realtime updates
  const handleRealtimeMessage = (message) => {
   
    if (
      message.changetype === "create" &&
      message.payload.searchDate.includes(currentdate)
    ) {
      setLogs((prevLogs) => [message.payload, ...prevLogs]);
    }
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_ACTIVITYLOG,
    handleRealtimeMessage
  );

  const filteredLogs = [...Logs].sort(
    (a, b) => new Date(b.dateLogged) - new Date(a.dateLogged)
  );

  return (
    <>
      {!isLoading && Logs.length === 0 ? (
        <div className=" w-full h-full flex justify-center items-center ">
          <span className="text-xl">No Logs in this Date.</span>
        </div>
      ) : (
        <div className="flex flex-col">
          {MainError && <Error errorMessage={MainError} />}
          {isLoading ? (
            <Logsskeleton />
          ) : (
            <>
              {Logs.length > 0 && (
                <>
                  {Logs &&
                    Logs.map((logs) => (
                      <div key={logs.$id} className="flex gap-x-3">
                        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-[#452063] dark:after:bg-[#452063]">
                          <div className="relative z-10 size-7 flex justify-center items-center">
                            <div className="size-2 rounded-full bg-[#452063] dark:bg-[#452063]"></div>
                          </div>
                        </div>

                        <div className="grow pt-0.5 pb-8">
                          <h3 className="flex gap-x-5.5 font-semibold text-gray-800 dark:text-white">
                            {logs.action}
                          </h3>
                          <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                            {logs.userCredentials?.role}
                          </p>
                          <div className="flex justify-between items-center">
                            <button
                              type="button"
                              className="-ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            >
                              <img
                                className="flex-shrink-0 size-4 rounded-full"
                                src="https://images.unsplash.com/photo-1659482633369-9fe69af50bfb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=3&w=320&h=320&q=80"
                                alt="Image Description"
                              />
                              {logs.userCredentials?.Username}
                            </button>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              {formatTime(logs.dateLogged)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </>
          )}{" "}
          {/* Show skeleton while loading */} {/* Show message if no logs */}
        </div>
      )}
    </>
  );
}
