import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  Select,
  SelectItem,
  TableCell,
  Chip,
  Skeleton
} from "@nextui-org/react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import { useEffect, useState } from "react";
import StudentSchedule from "./components/studentSchedule";
import TeacherSchedule from "./components/TeacherSchedule";
import axios from "axios";
import Error from "../../../Components/ErrorIndicator";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";

export default function Schedules() {
  const { user, role, DataServer } = useAuth();
  const days = [
    { label: "Monday", value: "Mon" },
    { label: "Tuesday", value: "Tue" },
    { label: "Wednesday", value: "Wed" },
    { label: "Thursday", value: "Thu" },
    { label: "Friday", value: "Fri" }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [MainError, setMainError] = useState("");
  const [isRefresh, setIsRefresh] = useState(true);
  const [selectedDay, setSelectedDay] = useState("Mon");
  const [schedule, setSchedule] = useState({});

  // Initializes day
  useEffect(() => {
    const currentDay = new Date().getDay();
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    setSelectedDay(
      daysOfWeek[currentDay] === "Sun" || daysOfWeek[currentDay] === "Sat"
        ? daysOfWeek[1]
        : daysOfWeek[currentDay]
    );
  }, [isRefresh]);

  // useEffect(() => {
  //   console.log(schedule);
  // }, []);

  // Get schedule
  useEffect(() => {
    async function scheduleResponse() {
      try {
        setIsLoading(true);
        const scheduleResponse = await axios.get(
          `${DataServer}/user/${role}/${user.$id}/schedule/${selectedDay}`
        );

        setSchedule(scheduleResponse.data);
      } catch (error) {
        setMainError(error.message);
      } finally {
        setIsLoading(false);
      }
    }

    if (selectedDay !== "") scheduleResponse();
  }, [isRefresh]);

  // Realtime Updates
  let debounceTimeout;

  const handleRealtimeMessage = (message) => {

    if (debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      setIsRefresh((prev) => !prev);
    }, 300);
  };

  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_SUBJECT_TEACHER,
    handleRealtimeMessage
  );

  return (
    <div className="flex flex-1 flex-col w-full ">
      {MainError && <Error errorMessage={MainError} reset={setMainError} />}
      <div className="flex-initial flex flex-col justify-between p-1 pt-6">
        <span className="text-4xl font-semibold text-[#72539b]">
          Class Schedule
        </span>
      </div>
      {isLoading ? (
        <div className="w-full relative flex flex-col h-full justify-center items-center p-2">
          <div className="absolute w-[90%] h-[80%] rounded-lg bg-secondary-200 transition-opacity animate-pulse"></div>
          <img
            className="z-10 size-60 bg-cover object-cover rounded-xl"
            src={require("../../../Img/scheduleFetching.gif")}
          />
          <div className="z-10 font-xs text-secondary p-2 text-xl w-full flex justify-center">
            Fetching your Schedules
          </div>
        </div>
      ) : (
        <div className="flex-1 overflow-auto ">
          <Card className="font-poppins rounded-md h-full max-w-full">
            {!role.includes("teacher") &&
              schedule.courseName &&
              schedule.sectionName &&
              schedule.schedules && (
                <CardHeader className="flex gap-3 justify-between">
                  <div className="font-medium text-md">
                    {schedule?.courseName || "Course"} |{" "}
                    {schedule?.sectionName || "Section"}
                  </div>
                  <Select
                    size="sm"
                    onSelectionChange={(key) => {
                      setSelectedDay(key.currentKey);
                    }}
                    label="Day"
                    selectedKeys={[selectedDay]}
                    placeholder="Select a day"
                    className="max-w-xs"
                  >
                    {days.map((day) => (
                      <SelectItem key={day.value} value={day.value}>
                        {day.label}
                      </SelectItem>
                    ))}
                  </Select>
                </CardHeader>
              )}

            <Divider />
            <CardBody className="p-0 h-full w-full max-w-full overflow-hidden">
              <div className=" w-full h-full overflow-auto">
                {/* Set overflow-x to auto for horizontal scrolling and a fixed height */}

                {/* The inner content should exceed the width to allow horizontal scrolling */}
                {schedule &&
                Object.keys(schedule).length > 0 &&
                schedule.schedules &&
                ((role.includes("teacher") &&
                  Object.keys(schedule.schedules).length > 0) ||
                  (role.includes("student") &&
                    Object.keys(schedule.schedules).length > 2)) ? (
                  <>
                    {user.labels.includes("teacher") && (
                      <TeacherSchedule schedule={schedule.schedules} />
                    )}
                    {user.labels.includes("student") && (
                      <StudentSchedule
                        schedule={schedule.schedules}
                        dayz={selectedDay}
                      />
                    )}
                  </>
                ) : (
                  <div className=" w-full h-full flex flex-col items-center justify-center">
                    <div className=" w-full flex justify-center items-center p-2">
                      <img
                        className=" size-80 bg-cover object-cover rounded-lg"
                        src={require("../../../Img/loading schedule.gif")}
                      />
                    </div>
                    <div className=" font-xs p-2 text-xl w-full flex justify-center">
                      {schedule.courseName && schedule.sectionName
                        ? "No Schedule yet"
                        : schedule.courseName && !schedule?.sectionName
                        ? "You are not assigned to any section yet"
                        : role === "teacher"
                        ? "You dont have any subject scheduled yet"
                        : "You are not assigned to any grade level yet"}
                    </div>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
}
