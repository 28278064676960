import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Checkbox,
  Input,
  Link
} from "@nextui-org/react";
import { useAuth } from "../../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import Error from "../../../../Components/ErrorIndicator";
import SectionNameValidation from "./SectionNameValidation";
import SectionOrder from "../components/renders/SectionOrder";

export default function AddSection({ Title, reload, courseid }) {

  const { server, user } = useAuth();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [isLoading, setisLoading] = useState(false);
  const [MainError, setMainError] = useState();

  const [data, setdata] = useState({
    creator: user.$id,
    id: courseid,
    name: "",
    description: "",
    sectionOrder: 0,
    capacity: ""
  });

  const [configs, setconfigs] = useState({
    isvalidating: false,
    istyping: false,
    duplicates: 0
  });

  const [Errors, setErrors] = useState({});

  useEffect(() => {

  }, [data]);
  //Validation logic
  let errors = {};
  const validate = () => {
    // Validate name
    if (data.name && !data.name.trim()) {
      errors.name = `Section name is required`;
    }

    if (!data.capacity.trim()) {
      errors.capacity = `Capacity is required`;
    } else if (parseInt(data.capacity.trim()) > 80) {
      errors.capacity = ``;
    }

    if (!data.sectionOrder === 0) {
      errors.sectionOrder = "Section order is not yet Loaded";
    }

    setErrors(errors);
  };

  const setOrder = (order) => {
    setdata((prevData) => ({
      ...prevData,
      sectionOrder: order
    }));
  };

  //Validate every changes
  useEffect(() => {
    validate();
  }, [data]);

  const sectionvalidations = (datas, isvalidating, istyping, duplicate) => {
    
    setdata({ ...data, name: datas || "" });
    setconfigs({
      isvalidating: isvalidating,
      istyping: istyping,
      duplicates: duplicate
    });
  };

  //Handles The process of adding new Section
  const handleAdd = async () => {
    validate();
    if (Object.keys(errors).length === 0) {
      setisLoading(true);
      try {
        const datatogive = {
          ...data,
          id: courseid
        };
        const response = await axios.post(
          `${server}/v1/section/new`,
          datatogive
        );
      } catch (e) {
        setMainError(e.message);
      } finally {
        setisLoading(false);
      }

      reload();
      setdata({
        creator: "",
        id: "",
        name: "",
        sectionOrder: 0,
        description: "",
        capacity: ""
      });
      onClose();
    } else {
      console.log(errors);
    }
  };


  return (
    <>
      {MainError && <Error errorMessage={MainError} />}
      <Button
        onPress={onOpen}
        className="bg-[#FFDE59] ml-1 rounded-lg text-black font-semibold hover:bg-[#fcd84b] overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
      >
        Add Section
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(CloseModal) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add new Section
              </ModalHeader>
              <ModalBody>
                <form className="w-full flex flex-col">
                  <>
                    {/* <label
                      for={`sectionnamse`}
                      className="block text-sm mb-2 dark:text-white font-medium"
                    >
                      Section Name
                      {Errors.name && (
                        <span className="text-red-500 text-sm mt-1 ml-1">
                          *
                        </span>
                      )}
                    </label>
                    <input
                      type="text"
                      name={`sectionname`}
                      id={`sectionname`}
                      className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        Errors.name == null
                          ? "border-slate-200"
                          : "border-red-500"
                      }`}
                      onKeyPress={(event) => {
                        if (data.name.length >= 50) {
                          event.preventDefault();
                        }
                      }}
                      required
                      defaultValue={data.name}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          name: e.target.value,
                        })
                      }
                    />
                    {Errors.name && (
                      <span className="text-red-500 text-sm mt-1">
                        {Errors.name}
                      </span>
                    )} */}

                    <div className="flex space-x-2">
                      <div className="flex-1">
                        <SectionNameValidation
                          SectionName={sectionvalidations}
                        />
                      </div>

                      <div className=" flex-initial h-full">
                        <SectionOrder CourseId={courseid} setorder={setOrder} />
                      </div>
                    </div>

                    <div>
                      <label
                        for={`capacity`}
                        className="block text-sm mb-2 font-medium dark:text-white"
                      >
                        Capacity
                        {Errors.capacity && (
                          <span className="text-red-500 text-sm mt-1 ml-1">
                            *
                          </span>
                        )}
                      </label>
                      <input
                        type="text"
                        name={`capacity`}
                        id={`capacity`}
                        className={`py-2 px-3 block w-full rounded-lg text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                          Errors.capacity == null
                            ? "border-slate-200"
                            : "border-red-500"
                        }`}
                        onKeyPress={(event) => {
                          if (
                            /[^0-9]/.test(event.key) ||
                            data.capacity.length >= 2
                          ) {
                            event.preventDefault();
                          }
                        }}
                        required
                        defaultValue={data.capacity}
                        onChange={(e) =>
                          setdata({
                            ...data,
                            capacity: e.target.value
                          })
                        }
                      />
                      {Errors.capacity && (
                        <span className="text-red-500 text-sm mt-1">
                          {Errors.capacity}
                        </span>
                      )}
                    </div>
                    <label
                      for={`sectiondescription`}
                      className="block text-sm mb-2 font-medium dark:text-white"
                    >
                      {Title} Description
                    </label>
                    <textarea
                      type="text"
                      name={`sectiondescription`}
                      id={`sectiondescription`}
                      className={`py-2 px-3 block w-full h-auto rounded-lg min-h-[150px] max-h-[300px] text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600 ${
                        Errors.description == null
                          ? "border-slate-200"
                          : "border-red-500"
                      }`}
                      required
                      defaultValue={data.description}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          description: e.target.value
                        })
                      }
                    />
                    {Errors.description && (
                      <span className="text-red-500 text-sm mt-1">
                        {Errors.description}
                      </span>
                    )}
                  </>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="flat"
                  onPress={() => {
                    setdata({
                      creator: "",
                      id: "",
                      name: "",
                      description: "",
                      capacity: ""
                    });
                    onClose();
                  }}
                >
                  Close
                </Button>
                {isLoading ? (
                  <Button
                    isLoading
                    color="secondary"
                    spinner={
                      <svg
                        className="animate-spin h-5 w-5 text-current"
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    isDisabled={
                      Object.keys(Errors).length > 0 ||
                      configs.isvalidating ||
                      configs.istyping ||
                      configs.duplicates > 0
                    }
                    color="secondary"
                    onPress={handleAdd}
                  >
                    Add Section
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
