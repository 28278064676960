export function calculateAge(birthdate) {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();

  // Check if the birthday hasn't occurred yet this year
  if (
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  if (birthdate) {
    return age;
  } else {
    return "N/A";
  }
}

// use this to format databased date
export function birthdateformat(cbirthdate, type) {
  const birthdate = new Date(cbirthdate);
  // Extract year, month, and day
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const year = birthdate.getFullYear();
  const day = birthdate.getDate().toString().padStart(2, "0");
  let month, formattedBirthdate;
  if (type === "format") {
    month = (birthdate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    formattedBirthdate = `${year}-${month}-${day}`;
  } else {
    month = monthNames[birthdate.getMonth()];
    formattedBirthdate = `${month} ${day} ${year}`;
  }

  // Create the formatted date string

  return formattedBirthdate;
}

//use this to get the current weekday today
export function Getweekday() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  return daysOfWeek[dayOfWeek];
}

//Use this to get the current date today
export function GetDateToday() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

//Use this to make the database default format to March 21, 2024 5:00pm format
export function formatTime(timestamp) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const date = new Date(timestamp);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hour = date.getHours();
  const minute = ("0" + date.getMinutes()).slice(-2);
  const ampm = hour >= 12 ? "pm" : "am";
  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'

  const formattedTime = `${month} ${day}, ${year} ${hour}:${minute}${ampm}`;
  return formattedTime;
}

export function getCurrentDateTime() {
  const now = new Date();
  const offset = 8; // Philippine time (PHT) is UTC+8
  const utc = now.getTime() + now.getTimezoneOffset() * 60000;
  const phTime = new Date(utc + 3600000 * offset);

  const year = phTime.getFullYear();
  const month = ("0" + (phTime.getMonth() + 1)).slice(-2);
  const day = ("0" + phTime.getDate()).slice(-2);
  const hour = ("0" + phTime.getHours()).slice(-2);
  const minute = ("0" + phTime.getMinutes()).slice(-2);
  const second = ("0" + phTime.getSeconds()).slice(-2);

  const formattedDateTime = `${year}-${month}-${day}T${hour}:${minute}:${second}.000+00:00`;
  return formattedDateTime;
}

export function getTodayFormatted() {
  const today = new Date();

  // Define options for formatting
  const options = { month: "long", day: "numeric", year: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  return formattedDate;
}

export function GetCurrentAcademicYear() {
  let CurrentAcademicYear;
  // Assuming academic year starts in September and ends in August
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index

  if (currentMonth >= 9) {
    // If the current month is September or later, it's part of the next academic year
    CurrentAcademicYear = `${today.getFullYear()}-${today.getFullYear() + 1}`;
  } else {
    // If the current month is before September, it's still part of the current academic year
    CurrentAcademicYear = `${today.getFullYear() - 1}-${today.getFullYear()}`;
  }

  return CurrentAcademicYear;
}

export function GetPreviousAcademicYears() {
  let currentAcademicYear;
  const today = new Date();
  const currentMonth = today.getMonth() + 1;

  // Determine the current academic year based on the current month
  if (currentMonth >= 9) {
    currentAcademicYear = `${today.getFullYear()}-${today.getFullYear() + 1}`;
  } else {
    currentAcademicYear = `${today.getFullYear() - 1}-${today.getFullYear()}`;
  }

  // Generate the list of previous 5 academic years including the current one
  const previousYears = [];
  const [startYear, endYear] = currentAcademicYear.split("-").map(Number);

  // Push the current and previous five academic years
  for (let i = 0; i < 6; i++) {
    previousYears.push(`${startYear - i}-${endYear - i}`);
  }

  return previousYears;
}

// Usage
const academicYears = GetPreviousAcademicYears();

