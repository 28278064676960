import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Image,
  Pagination,
  Select,
  SelectItem,
  Skeleton,
  Spacer
} from "@nextui-org/react";
import Enrolledsubjectsrender from "../../../Components/Renders/Enrolledsubjectsrender";
import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/AppwriteConfig/AuthContext";
import axios from "axios";
import CardSkeleton from "../../../Components/skeleton/Cards";
import NewFile from "./actions/NewFile";
import Viewdocument from "./components/Viewdocument";
import Error from "../../../Components/ErrorIndicator";
import SelectedCourse from "./components/SelectCourse";
import useRealtimeSubscription from "../../../utils/AppwriteConfig/RealtimeServices";
import DocumentType from "./components/DocmentTypes";

export default function Documents() {
  const { DataServer } = useAuth();

  const [isLoading, setisLoading] = useState(false);
  const [Refresh, setRefresh] = useState(false);
  const [MainError, setMainError] = useState();

  const [selecteddocument, setselecteddocument] = useState({});

  //ACC
  const [selectedAccType, setSelectedAccType] = useState("All");
  const [oldSelectedAcc, setOldSelectedAcc] = useState("All");
  const [employeeType, setEmployeeType] = useState("All");
  const [course, setcourse] = useState("All");
  const [users, setusers] = useState([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [Pages, setPages] = useState({
    total: 0,
    current: 1
  });

  //For selecting what user to show
  const Type = [
    { label: "All", value: "All" },
    { label: "Employee", value: "employee" },
    { label: "Teacher", value: "teacher" },
    { label: "Student", value: "student" }
  ];

  const employeeSelectType = [
    { label: "Admin Head", value: "Adminhead" },
    { label: "Registrar", value: "Registrar" },
    { label: "Scheduler", value: "Scheduler" }
  ];

  const [search, setSearch] = useState("");
  // used to prevent multiple request, Itll only request after the user is done typing
  useEffect(() => {
    setisLoading(true);
    const timerId = setTimeout(() => {
      setsearchQuery(search);
    }, 500);

    // Clean up the timer
    return () => clearTimeout(timerId);
  }, [search]);

  //Fetch all Activated Users
  useEffect(() => {
    setisLoading(true);
    async function fetchsubj() {
      try {
        let users;
        if (searchQuery.trim()) {
          users = await axios.get(`${DataServer}/user/accounts/search`, {
            params: {
              page: Pages.current,
              search: searchQuery
            }
          });
  
        } else {
          if (selectedAccType === "employee") {
    
            //If selected employee type is all
            if (employeeType === "All") {
              //Show all employee activated acc
             
              users = await axios.get(
                `${DataServer}/user/v1/${selectedAccType}/accounts`,
                {
                  params: {
                    page: Pages.current
                  }
                }
              );
            } else {
              //Show all student activated acc based on role
      
              users = await axios.get(
                `${DataServer}/user/${employeeType}/accounts`,
                {
                  params: {
                    page: Pages.current
                  }
                }
              );
            }
          } else if (selectedAccType === "teacher") {
            //Show all teacher activated acc
            users = await axios.get(
              `${DataServer}/user/${selectedAccType}/accounts`,
              {
                params: {
                  page: Pages.current
                }
              }
            );
          } else if (selectedAccType === "student") {
            if (course === "All" || course === "") {
 
              //Show all student activated acc
              users = await axios.get(
                `${DataServer}/user/${selectedAccType}/accounts`,
                {
                  params: {
                    page: Pages.current
                  }
                }
              );
            } else {
  
              //Show all student activated acc based on specific course
              users = await axios.get(
                `${DataServer}/user/student/enrolled/${course}`,
                {
                  params: {
                    page: Pages.current
                  }
                }
              );
            }
          } else {
            users = await axios.get(`${DataServer}/user/active`, {
              params: {
                page: Pages.current
              }
            });
       
          }
        }

        //If pages is null then do this

        //Get total pages for all user
        const totalPages = Math.ceil(users.data.total / 10);
        setPages({ ...Pages, total: totalPages });
        setusers(users.data.documents);
      } catch (error) {
        console.log(error);
        // Handle any errors
        setMainError(error.message);
      } finally {
        setisLoading(false);
      }
    }
    fetchsubj();
  }, [
    Refresh,
    Pages.current,
    selectedAccType,
    employeeType,
    course,
    searchQuery
  ]);

  useEffect(() => {

  }, [users]);

  //Used for the children components to refresh this Page
  const Rerenderpage = () => {
    setRefresh(!Refresh);
  };



  const HandleRealtimeMessages = (changes) => {


    if (
      changes.changetype === "update" &&
      changes.payload.deleteFlag === false
    ) {
      //
      const updatedUserIndex = users.findIndex(
        (user) => user.$id === changes.payload.$id
      );
      if (updatedUserIndex !== -1) {
        const updatedUsers = [...users];
        updatedUsers[updatedUserIndex] = changes.payload;
        setusers(updatedUsers);
      } else {
        setRefresh(!Refresh);
      }
    } else if (
      changes.changetype === "delete" ||
      (changes.changetype === "update" &&
        (changes.payload.deleteFlag === true ||
          changes.payload.deleteFlag === null))
    ) {
      const filteredUsers = users.filter(
        (user) => user.$id !== changes.payload.$id
      );
      setusers(filteredUsers);
    } else if (changes.changetype === "create") {
      setRefresh(!Refresh);
    }
  };
  useRealtimeSubscription(
    process.env.REACT_APP_MAIN_DB,
    process.env.REACT_APP_USERCRED,
    HandleRealtimeMessages
  );

  return (
    <div className=" w-full h-full">
      <div className="flex h-full flex-col space-y-2">
        {/* {MainError && <Error errorMessage={MainError} />} */}
        <div className="flex-initial flex flex-col justify-between p-1 pt-6">
          <div className="flex justify-between items-center">
            <span className=" text-4xl font-semibold text-[#72539b]">
              Documents
            </span>
          </div>

          <div className=" text-lg mt-2  font-medium flex flex-col sm:flex-row justify-end items-end">
            <div className="flex-1 flex w-full pr-0 mb-2 border-0 ring-0 sm:pr-6 sm:mb-0 relative">
              {/* Search icon */}
              <div className="absolute inset-y-0 left-3 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
              {/* Search user input */}
              <input
                type="text"
                placeholder="Search"
                className="py-2 pl-10 pr-2 border-0 shadow-md block w-full rounded-lg bg-default text-black/90 dark:text-white/90 text-sm focus:border-[#A16AE8] focus:ring-[#A16AE8] disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {/* Clear button */}
              {searchQuery && (
                <div
                  className="absolute inset-y-0 right-9 flex cursor-pointer items-center "
                  onClick={() => {
                    setsearchQuery("");
                    setSearch("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg>
                </div>
              )}
            </div>
            <div className=" md:flex-initial w-full  md:w-auto justify-start md:justify-end flex">
              <Spacer />
              {searchQuery.trim() ? (
                <Select
                  labelPlacement="outside"
                  label="Account Type"
                  placeholder="Select Account Type"
                  className="min-w-[120px]"
                  isDisabled
                ></Select>
              ) : (
                <Select
                  labelPlacement="outside"
                  label="Account Type"
                  isDisabled={isLoading}
                  placeholder="Select Account Type"
                  className="min-w-[120px]"
                  onChange={(value) => {
                    setSelectedAccType(value.target.value);
                    setPages({ ...Pages, current: 1 });
                  }} // Set the selected value to selectedAccType state variable
                  value={selectedAccType}
                >
                  {Type.map((type) => (
                    <SelectItem key={type.value} value={type.value}>
                      {type.label}
                    </SelectItem>
                  ))}
                </Select>
              )}

              <Spacer />

              {selectedAccType !== "employee" &&
                selectedAccType !== "student" && (
                  <Select
                    placeholder="Select User Type"
                    isDisabled
                    className="min-w-[120px]"
                  >
                    {Type.map((type) => (
                      <SelectItem key={type.value} value={type.value}>
                        {type.label}
                      </SelectItem>
                    ))}
                  </Select>
                )}

              {selectedAccType === "employee" && (
                <Select
                  placeholder="Select User Type"
                  className="min-w-[120px]"
                  onChange={(value) => {
                    setEmployeeType(value.target.value || "All");
                    setPages({ ...Pages, current: 1 });
                  }}
                >
                  {employeeSelectType.map((type) => (
                    <SelectItem key={type.value} value={type.value}>
                      {type.label}
                    </SelectItem>
                  ))}
                </Select>
              )}

              {selectedAccType === "student" && (
                <SelectedCourse updatecourse={setcourse} />
              )}
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col shadow-lg rounded-xl p-1 justify-between overflow-hidden bg-white">
          <div className="  flex flex-col w-full h-full overflow-auto p-1">
            {/* This occupies the remaining space */}
            {isLoading ? (
              <div className="block">
                {/* I want my grid to only occupy the space he needs so it will not have huge gap between items */}
                <div className="grid p-3 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 h-auto">
                  {/* Loading show skeleton | Show all users */}
                  <>
                    {/* Render skeleton cards when loading */}
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                    <CardSkeleton />
                  </>
                </div>
              </div>
            ) : (
              <>
                {users.length > 0 ? (
                  <div className="grid p-3 gap-2 sm:grid-cols-3 md:grid-cols-4 ">
                    {users &&
                      users.map((CurrentUser) => (
                        <DocumentCard user={CurrentUser} />
                      ))}
                  </div>
                ) : (
                  <div className=" w-full h-full text-lg flex justify-center items-center">
                    No Users here
                  </div>
                )}
              </>
            )}

            {/* This is a modal to render unique selected user to see specific documents */}
            {selecteddocument && (
              <Viewdocument
                user={selecteddocument}
                rerenderparent={Rerenderpage}
              />
            )}
          </div>
          <div className=" flex-initial flex justify-center">
            {/* Pagination for the documents */}
            <Pagination
              isDisabled={isLoading}
              className="flex-initial p-4"
              loop
              showControls
              color="secondary"
              total={Pages.total}
              initialPage={1}
              page={Pages.current}
              onChange={(setCurrentPage) =>
                setPages((prevPages) => ({
                  ...prevPages,
                  current: setCurrentPage
                }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
  function DocumentCard({ user }) {
    const [totalDocs, setTotalDocs] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    const [Refresh, setRefresh] = useState(false);
    const [MainError, setMainError] = useState();

    useEffect(() => {
      async function GetData() {
        try {
          setisLoading(true);
          const gettotalDocs = await axios.get(
            `${DataServer}/info/docs/total`,
            {
              params: { id: user.$id }
            }
          );
          setTotalDocs(gettotalDocs.data.total);
        } catch (e) {
          setMainError(e.message);
        } finally {
          setisLoading(false);
        }
      }
      GetData();
    }, []);
    return (
      <Card
        key={user?.$id}
        isPressable
        onPress={() => {
          setselecteddocument(user);
          document.querySelector("#viewdocs").click();
        }}
        shadow="sm"
        className=" rounded-md min-h-44 max-h-52 overflow-hidden relative transition-transform duration-300 transform hover:scale-105"
      >
        <span className="absolute top-2 right-2  z-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill={user.deleteFlag === null ? "#F44336" : "#4CAF50"}
            class="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
        </span>
        <Image
          removeWrapper
          alt="Card background"
          className="z-0 w-full h-full object-cover"
          src={require("../../../Img/BSA.jpg")}
        />
        <CardFooter className="absolute bg-[#e5dbf9]/90 bottom-0 h-full z-10 rounded-md">
          <div className=" relative flex flex-col justify-end w-full h-full">
            <div
              className={` absolute top-2 right-2 ${
                user.deleteFlag === null ? "text-[#dc3545]" : "text-[#008000]"
              }`}
            >
              {user.deleteFlag === null ? "Disabled" : "Active"}
            </div>
            <div className=" flex flex-col justify-start text-start w-full">
              <div className=" text-lg text-[#4a306d] font-semibold mb-1">
                {user?.Username}
                {user?.student?.firstName} {user?.student?.lastName}
              </div>
              <div className="text-sm text-[#7d5ca3]">
                Role: {user?.role}
                {user.student && <>Student | {user?.course?.courseName}</>}
              </div>
              <div className="text-sm text-[#7d5ca3]">
                Total Documents: <b>{totalDocs}</b>
              </div>
            </div>
          </div>
          {/* <div className=" text-medium flex flex-col items-start text-black/90">
              <span>{user?.accountType}</span>
              <span className="">
                {user?.Username}
                {user?.student?.firstName}{" "}
                {user?.student?.lastName}
              </span>
            </div>
            <p className="text-tiny text-white/90">
              {user?.role}
              {user.student && (
                <>Student | {user?.course?.courseName}</>
              )}
            </p> */}
        </CardFooter>
      </Card>
    );
  }
}
